// @flow

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Componentify from "react-componentify";

import Modal, { CloseModalIcon } from "../Modal";
import { Loader } from "../../containers/TwoFactorAuth/SharedComponents";
import { ButtonCentered } from "../ButtonDefault";
import * as Style from "./styledComponents";

import { __ } from "../../lib/translate";
import apiMethod from "../../api/apiMethod";
import { styledSpanTagConverter } from "../../lib/componentifyConverters";

import useIsMount from "../../../web-shared/hooks/useIsMount";

const customConverter = Object.assign({}, styledSpanTagConverter, {
  props: {
    style: {
      color: "#17bed0",
      paddingLeft: "5px",
      maxWidth: "300px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }
});

type Props = {
  documentName: string,
  method?: string,
  params?: object,
  iframeSrc?: any,
  offsetX: string,
  offsetY: string,
  prevplcCss?: object
};

const PreviewDocumentModal = ({
  documentName,
  method,
  params,
  iframeSrc,
  offsetX,
  offsetY,
  prevplcCss
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);

  const iframeEl = useRef(null);
  const isMount = useIsMount();

  useEffect(() => {
    if (fileext(documentName) == "txt") {
      apiMethod(
        method,
        params,
        ret => {
          $(".prevplc")
            .css(prevplcCss)
            .html(htmlentities(ret).replace(/\n/g, "<br>"));
        },
        { dataType: "text" }
      );
    }
  }, [method, params]); //[method, params]

  useEffect(() => {
    if (iframeEl.current) {
      setIsLoadingIframe(false);
    }
  }, [isMount, iframeEl]); //[isMount, iframeEl]

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal shouldCloseOnOverlayClick={false} animate onClose={handleClose} opened={isOpen}>
      <ModalContainer offsetX={offsetX} offsetY={offsetY}>
        <CloseModalIcon onClick={handleClose} />
        <ContainerW offsetX={offsetX} offsetY={offsetY}>
          <Componentify
            text={`${__("Preview Document", "Preview Document")}<span>"${documentName}"</span>`}
            converters={[customConverter]}
          />
          {iframeSrc ? (
            <WrapperIframe offsetY={offsetY}>
              {isLoadingIframe ? <Loader /> : null}
              <IframeLink
                offsetX={offsetX}
                offsetY={offsetY}
                show={!isLoadingIframe}
                ref={iframeEl}
                src={iframeSrc}
                scrolling="yes"
              />
            </WrapperIframe>
          ) : (
            <div>
              <TextFileWrapper offsetY={offsetY} className="prevplc" />
              <ButtonCentered
                inline
                color="lightgray4"
                style={{
                  marginRight: "5px",
                  maxWidth: "300px"
                }}
                onClick={handleClose}
              >
                {__("Close", "Close")}
              </ButtonCentered>
            </div>
          )}
        </ContainerW>
      </ModalContainer>
    </Modal>
  );
};

export default PreviewDocumentModal;

const TextFileWrapper = styled.div`
  margin: 30px 0;
  text-align: left;
  width: 100%;
  height: ${props => props.offsetY - 200}px;
  font-weight: 400;
  font-size: 13px;
`;

const ContainerW = styled(Style.Container)`
  width: ${props => props.offsetX}px;
`;

const ModalContainer = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.offsetX}px;
  max-height: ${props => props.offsetY}px;
  background: #fff;
  border-radius: 4px;
  display: inline-block;
  overflow: hidden;
`;

const WrapperIframe = styled.div`
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: ${props => props.offsetY - 175}px;
`;

const IframeLink = styled.iframe`
  border: none;
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  background-color: #fff;
  overflow: hidden;
`;
