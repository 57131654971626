// flow

import React from "react";
import styled from "styled-components";

import NavigationLeftIcon from "../../../root/img/svg/gallery/chevron_left.svg";
import NavigationRightIcon from "../../../root/img/svg/gallery/chevron_right.svg";

type Props = {
  currentIndex: number,
  total: number,
  hideArrows: boolean,
  visibility: boolean,
  handleNextClick: () => void,
  handlePrevClick: () => void
};

function Navigation({
  currentIndex,
  total,
  hideArrows,
  visibility = true,
  handlePrevClick = () => {},
  handleNextClick = () => {},
  style = {}
}: Props) {
  return (
    <NavigationWrapper className="media-preview-navigation" hideArrows={hideArrows} visibility={visibility} style={style}>
      <NavigationIconWrapper onClick={handlePrevClick}>
        <NavigationLeftIcon className="arrow" width="24px" height="24px" />
      </NavigationIconWrapper>
      <span className="navigation-text no-select">{`${currentIndex + 1}`} <span>{__("of", "of")}</span> {`${total}`}</span>
      <NavigationIconWrapper onClick={handleNextClick}>
        <NavigationRightIcon className="arrow" width="24px" height="24px" />
      </NavigationIconWrapper>
    </NavigationWrapper>
  );
}

export default Navigation;

const NavigationWrapper = styled.div`
  display: ${({ visibility }) => (visibility ? "flex" : "none")};

  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  line-height: normal;
  color: rgba(255, 255, 255, 0.8);

  svg {
    cursor: pointer;
  }

  & > .arrow {
    visibility: ${({ hideArrows }) => (hideArrows ? "visible" : "hidden")};
  }

  .navigation-text {
    span {
      color: rgba(255, 255, 255, 0.48);
    }
  }
`;

const NavigationIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  outline: none;
  user-select: none;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;
