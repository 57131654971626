//@flow

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Componentify from "react-componentify";
import { boldConverter } from "../lib/componentifyConverters";

import { __ } from "../lib/translate";
import { detectIsMobile } from "../lib/utils";

import apiMethod from "../api/apiMethod";
import apiConfig from "../api/config";

import EmailVerifyIcon from "../../root/img/svg/email-verify-icon.svg";
import EmailVerifyIconMobile from "../../root/img/svg/email-verify-icon-mobile.svg";
import BulbIcon from "../../root/img/svg/bulb.svg";

import Button from "./ButtonDefault";
import { ButtonLink } from "../containers/Business/BillingPage/StyledComponents";

const VerifyEmailPage = ({ code = "" }: { code: string }) => {
  const [verificationInProgress, setVerificationInProgress] = useState(true);
  const [verifiedEmail, setVerifiedEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isBusiness, setIsBusiness] = useState(false);

  const userLang = useSelector(({ user }) => user.userinfo.language);
  const isMobileDevice = detectIsMobile();

  const langToGettingStartedPdf = {
    en: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud.pdf",
    de: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting_started_with_pCloud_de.pdf",
    fr: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting_started_with_pCloud_fr.pdf",
    ja: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting_started_with_pCloud_ja.pdf",
    nl: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting_started_with_pCloud_NL.pdf"
  };
  const DEFAULT_GUIDE_LINK =
    "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud.pdf";

  const langToGettingStartedPdfBusiness = {
    en: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business.pdf",
    de: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business%20-%20DE.pdf",
    fr: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business%20-%20FR.pdf",
    ja: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business_ja.pdf",
    nl: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20for%20Bussines%20NL.pdf",
    es: "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business%20ES.pdf"
  };
  const DEFAULT_BUSINESS_GUIDE_LINK =
    "https://filedn.com/leqRL4e6f0M5a59t0DxqMCY/Getting%20started%20with%20pCloud%20Business.pdf";

  let gettingStartedLink = langToGettingStartedPdf[userLang] || DEFAULT_GUIDE_LINK;

  useEffect(() => {
    apiMethod(
      "verifyemail",
      { code: code },
      ret => {
        setVerifiedEmail(ret.email);
        setVerificationInProgress(false);

        if (ret.isbusiness) {
          setIsBusiness(true);
        }

        HFN.psettings.init();
      },
      {
        errorCallback: ({ result, error }) => {
          if (result === 2013) {
            setErrorMessage(__("email_already_verified", "Email is already verified"));
          } else {
            setErrorMessage(error);
          }
        },
      }
    );
  }, []); //[]

  const onGoToMyPcloudClick = () => {
    window.open("https://my.pcloud.com", "_self");
  };

  if (isBusiness) {
    gettingStartedLink = langToGettingStartedPdfBusiness[userLang] || DEFAULT_BUSINESS_GUIDE_LINK;
  }

  return (
    <Wrapper>
      {verificationInProgress ? (
        errorMessage ? (
          <Header style={{ fontWeight: "normal" }}>{errorMessage}</Header>
        ) : (
          <Header style={{ fontWeight: "normal" }}>{__("Verifying in progress ...")}</Header>
        )
      ) : (
        <div>
          {isMobileDevice ? <EmailVerifyIconMobile /> : <EmailVerifyIcon />}
          <Header>{__("verify_mail_title", "Account verified")}</Header>
          <StyledSpan>
            <Componentify
              text={__("verify_mail_desc").replace("%verified_email%", verifiedEmail)}
              converters={[boldConverter]}
            />
          </StyledSpan>
          <BoxMessage>
            <BulbIcon />
            <Message>
              {__(
                "verify_mail_getstarted",
                "Click on the button below for some useful tips & tricks on how to use your pCloud account to the fullest."
              )}
            </Message>
            <Button href={gettingStartedLink} target="_blank" inline>
              {__("verify_mail_getstarted_cta")}
            </Button>
          </BoxMessage>
          <ButtonLinkStyled onClick={onGoToMyPcloudClick}>
            {__("verify_mail_my", "Go to my Account")}
          </ButtonLinkStyled>
        </div>
      )}
    </Wrapper>
  );
};

export default VerifyEmailPage;

const Wrapper = styled.div`
  font-family: Roboto;
`;

const Header = styled.div`
  display: block;
  height: 30px;
  min-height: 30px;
  font-size: 22px;
  font-weight: bold;
  color: #000;
  padding-top: 15px;
`;

const StyledSpan = styled.div`
  font-size: 19px;
  font-weight: normal;
  line-height: 22px;
`;

const BoxMessage = styled.div`
  max-width: 408px;
  border-radius: 13px;
  border: solid 1px #efefef;
  background-color: #f6f6f6;
  margin: 90px auto 30px;
  padding: 20px 55px;
`;

const Message = styled.div`
  font-size: 17px;
  color: #000;
  line-height: 20px;
  text-align: center;
  margin-bottom: 15px;
`;

const ButtonLinkStyled = styled(ButtonLink)`
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #17bed0;
  text-decoration: none;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    margin-left: 11px;
    display: block;
    border-right: 2px solid #17bed0;
    border-bottom: 2px solid #17bed0;
    width: 5px;
    height: 5px;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
