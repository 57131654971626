import React, {useEffect, useRef, useState} from 'react';
import {FixedSizeList as List} from "react-window";
import {
    DragDropContext,
    Droppable,
    useKeyboardSensor,
    useMouseSensor
} from "react-beautiful-dnd";
import usePlayerContext from "../../../hooks/usePlayerContext";
import useTouchSensor from '../../../hooks/touchSensors';
import {
    BoxQueueListWrapper,
    BoxQueueWrapper, BoxScrollableQueueWrapper, DivWrapper, ShowAppButtonQueueWrapper
} from "../../../userinterface/styles";
import AutoSizer from "react-virtualized-auto-sizer";
import OpenAppButton from "../../../userinterface/OpenAppButton";
import Item from "./Item";
import Row from "./Row";
import useWindowDimensions from "../../../../../lib/hooks/useWindowDimensions";
import {getPlayerState} from "../../../helpers";

const Queue = () => {
    const {
        playerBoxRef,
        boxPlayerRef,
        headerRef,
        currentSong,
        setQueue,
        queue,
        isPublic,
        isMobile,
        playerVariant,
        additionalClass,
        showOpenInAppButton
    } = usePlayerContext();

    const listRef = useRef();

    const {height} = useWindowDimensions();
    const [topPartHeight, setTopPartHeight] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isDragProcess, setIsDragProcess] = useState(false);

    useEffect(() => {
        let timeout;
        let timeout2;

        timeout = setTimeout(() => {
            setTopPartHeight(boxPlayerRef.current?.offsetHeight + headerRef.current?.offsetHeight)
            setContainerHeight(playerBoxRef.current.offsetHeight - (boxPlayerRef.current?.offsetHeight + headerRef.current?.offsetHeight));

            if (!isMobile) {
                _updateScrollPosition(currentSong);
            }
        }, 350);

        if (isMobile) {
            timeout2 = setTimeout(() => {
                _updateScrollPosition(currentSong);
            }, 600);
        }

        return () => {
            clearTimeout(timeout);
            clearTimeout(timeout2);
        }
    }, [playerVariant.isMiniPlayer]);

    useEffect(() => {
        if (!containerHeight || !topPartHeight || !isMobile) {
            return;
        }
        setContainerHeight(playerBoxRef.current.offsetHeight - (boxPlayerRef.current?.offsetHeight + headerRef.current?.offsetHeight));
    }, [height]);

    useEffect(() => {
        _updateScrollPosition(currentSong);
    }, [currentSong.fileid]);

    const _updateScrollPosition = (song, type = "smart") => {
        const index = queue.findIndex((item) => {
            return item.fileid === song.fileid;
        });
        listRef.current?.scrollToItem(index, type)
    }

    const showAppButton = isPublic && isMobile && !playerVariant.isMinimize && !playerVariant.isMiniPlayer && playerVariant.showPlayer;

    const handleSort = (results) => {
        const {source, destination} = results;

        setIsDragProcess(false);

        if (!destination) {
            return;
        }

        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        )  {
            return;
        }

        const queueClone = [...queue];

        const [removedItem] = queueClone.splice(source.index, 1);
        queueClone.splice(destination.index, 0, removedItem);

        setQueue(queueClone);

        if (typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "queue reorder",
                state: getPlayerState(playerVariant)
            });
        }
    }

    const handleDragStart = () => {
        setIsDragProcess(true);
    }

    return (
        <BoxQueueWrapper
            $topPartHeight={topPartHeight}
            className={additionalClass + (isDragProcess ? " is-dragging-over" : "")}
            initial={{ height: 0, opacity: 0 }}
            animate={{
                height: isMobile ? (
                    containerHeight ? containerHeight : playerBoxRef.current.offsetHeight - topPartHeight
                ) + 2 : 239,
                opacity: 1
            }}
            exit={{ height: 0, opacity: 0 }}
            transition={{duration: 0.3}}
        >
            {showAppButton && showOpenInAppButton && (
                <ShowAppButtonQueueWrapper>
                    <OpenAppButton />
                </ShowAppButtonQueueWrapper>
            )}
            <DragDropContext
                enableDefaultSensors={false}
                sensors={[useMouseSensor, useKeyboardSensor, useTouchSensor]}
                onDragEnd={handleSort}
                onDragStart={handleDragStart}
            >
                <Droppable
                    droppableId="queueList"
                    mode="virtual"
                    renderClone={(provided, snapshot, rubric) => {
                        return (
                            <Item
                                provided={provided}
                                isDragging={snapshot.isDragging}
                                item={queue[rubric.source.index]}
                            />
                        )
                    }}
                >
                    {(provided) => (
                        <BoxQueueListWrapper
                            className={additionalClass}
                            ref={provided.innerRef}
                        >
                            <BoxScrollableQueueWrapper>
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            className={additionalClass + " List"}
                                            ref={listRef}
                                            itemCount={queue.length}
                                            itemSize={45}
                                            width={width}
                                            height={height}
                                            outerRef={provided.innerRef}
                                        >
                                            {Row}
                                        </List>
                                    )}
                                </AutoSizer>
                            </BoxScrollableQueueWrapper>
                        </BoxQueueListWrapper>
                    )}
                </Droppable>
            </DragDropContext>
        </BoxQueueWrapper>
    );
}

export default Queue;
