//@flow

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { hiDPI } from "polished";
import Componentify from "react-componentify";

import ThirdPartyBackupBox from "./ThirdPartyBackupBox";
import BackupPromoBox from "./BackupPromoBox";
import apiMethod from "../../api/apiMethod";
import ActionModal from "./ActionModal";
import { findIndex } from "../../../web-shared/utils";
import { linkConverter, linkConverterV2 } from "../../lib/componentifyConverters";
import { __ } from "../../lib/translate";

type Props = {
  snimp: any,
  GDrive: any,
  Dropbox: any,
  Facebook: any,
  Instagram: any,
  OneDrive: any,
  GPhotos: any,
  DropboxBusiness: any
}

const ThirdPartyBackupsContainer = ({
  snimp,
  GDrive,
  Dropbox,
  DropboxBusiness,
  Facebook,
  Instagram,
  OneDrive,
  GPhotos
} : Props) => {
  const DEFAULT_MODAL_SETTINGS = {
    isOpened: false,
    isLoading: false,
    primaryClick: () => {},
    primaryText: "",
    secondaryClick: () => {},
    secondaryText: "",
    title: "",
    description: "",
    primaryColor: "",
    onClose: () => {}
  }

  const SOURCES = {
    odn: odSource,
    dbxn: dbxSource,
    dbxb: dbxBSource,
    fb: fbSource,
    //gplus: gpSource,
    ig: igSource,
    pi: piSource,
    copy: copySource,
    gdriven: gdriveSource,
    gphotos: gphotosSource
  }

  const DEFAULT_ONGOING_BACKUPS_STATE = [
    {
      sourceid: "gphotos",
      backup_folder: null,
      next_execution: null,
      backuped_albums: 0,
      total_albums: 0,
      is_aborted: false,
      is_running: false,
      is_paused: false,
      is_stopped: false,
      is_inactive: true,
      unavailable: false,
      mode: 0,
      source: "Google Photos",
      created: null,
      downloaded_photos: null,
      exit_code: null,
      finished: null,
      started: null,
      srcobj: GPhotos,
      type: "ongoing",
      is_allowed_to_backup: true,
    },
    {
      sourceid: "gdriven",
      backup_folder: null,
      next_execution: null,
      backuped_albums: 0,
      total_albums: 0,
      is_aborted: false,
      is_running: false,
      is_paused: false,
      is_stopped: false,
      is_inactive: true,
      unavailable: false,
      mode: 0,
      source: "Google Drive",
      created: null,
      downloaded_photos: null,
      exit_code: null,
      finished: null,
      started: null,
      srcobj: GDrive,
      type: "onetime",
      is_allowed_to_backup: true,
    },
    {
      sourceid: "odn",
      backup_folder: null,
      next_execution: null,
      backuped_albums: 0,
      total_albums: 0,
      is_aborted: false,
      is_running: false,
      is_paused: false,
      is_stopped: false,
      is_inactive: true,
      unavailable: false,
      mode: 0,
      source: "OneDrive",
      created: null,
      downloaded_photos: null,
      exit_code: null,
      finished: null,
      started: null,
      srcobj: OneDrive,
      type: "onetime",
      is_allowed_to_backup: true,
    },
    {
      sourceid: "dbxn",
      backup_folder: null,
      next_execution: null,
      backuped_albums: 0,
      total_albums: 0,
      is_aborted: false,
      is_running: false,
      is_paused: false,
      is_stopped: false,
      is_inactive: true,
      unavailable: false,
      mode: 0,
      source: "Dropbox",
      created: null,
      downloaded_photos: null,
      exit_code: null,
      finished: null,
      started: null,
      srcobj: Dropbox,
      type: "onetime",
      is_allowed_to_backup: true,
    },
    {
      sourceid: "dbxb",
      backup_folder: null,
      next_execution: null,
      backuped_albums: 0,
      total_albums: 0,
      is_aborted: false,
      is_running: false,
      is_paused: false,
      is_stopped: false,
      is_inactive: true,
      unavailable: false,
      mode: 0,
      source: "DropboxBusiness",
      created: null,
      downloaded_photos: null,
      exit_code: null,
      finished: null,
      started: null,
      srcobj: DropboxBusiness,
      type: "onetime",
      is_allowed_to_backup: true,
    },
    {
      sourceid: "fb",
      backup_folder: null,
      next_execution: null,
      backuped_albums: 0,
      total_albums: 0,
      is_aborted: false,
      is_running: false,
      is_paused: false,
      is_stopped: false,
      is_inactive: true,
      unavailable: true,
      mode: 0,
      source: "Facebook",
      created: null,
      downloaded_photos: null,
      exit_code: null,
      finished: null,
      started: null,
      srcobj: Facebook,
      type: "ongoing",
      is_allowed_to_backup: true,
    },
    {
      sourceid: "ig",
      backup_folder: null,
      next_execution: null,
      backuped_albums: 0,
      total_albums: 0,
      is_aborted: false,
      is_running: false,
      is_paused: false,
      is_stopped: false,
      is_inactive: true,
      unavailable: true,
      mode: 0,
      source: "Instagram",
      created: null,
      downloaded_photos: null,
      exit_code: null,
      finished: null,
      started: null,
      srcobj: Instagram,
      type: "ongoing",
      is_allowed_to_backup: true,
    }
  ];

  const [backupsState, setBackupsState] = useState(DEFAULT_ONGOING_BACKUPS_STATE);
  const [modalSettings, setModalSettings] = useState(DEFAULT_MODAL_SETTINGS);
  const [isBackupStateLoading, setIsBackupStateLoading] = useState(true)

  const getBackupsByType = (type) => {
    return backupsState.filter(({ type: currentBackupType }) => currentBackupType === type);
  }

  const oneTimeBackups = getBackupsByType("onetime");
  const ongoingBackups = getBackupsByType("ongoing");

  let updateTimeout = useRef(null);
  const usedquota = useSelector(({ user }) => user.userinfo.usedquota);
  const quota = useSelector(({ user }) => user.userinfo.quota);
  const isPremium = useSelector(({ user }) => user.userinfo.premium);
  const isBusiness = useSelector(({ user }) => user.userinfo.business);
  const isOverquota = usedquota >= quota;

  useEffect(() => {
    updateBackupsState();
  }, []);

  useEffect(() => {
    clearTimeout(updateTimeout.current);
    const hasRunningBackup = backupsState.some(({ is_running, is_paused }) => is_running || is_paused);

    console.log('hasRunningBackup', hasRunningBackup);
    if (hasRunningBackup) {
      updateTimeout.current = setTimeout(() => {
        updateBackupsState();
      }, 15000)
    }
  }, [backupsState])

  const closeModal = () => {
    setModalSettings(DEFAULT_MODAL_SETTINGS);
  }

  const openRestartModal = ({ backupID }) => {
    const sourceData = new SOURCES[backupID]();

    setModalSettings({
      isOpened: true,
      primaryClick: () => restartBackup({ backupID }),
      primaryText: __("Restart Backup"),
      secondaryClick: closeModal,
      secondaryText: __("Cancel"),
      title: "Restart backup of: " + sourceData.title,
      description: "",
      primaryColor: "green",
      onClose: closeModal
    });
  }

  const restartBackup = ({ backupID }) => {
    const backupStateCopy = [ ...backupsState ];
    const backupObj = backupStateCopy.find(({ sourceid }) => sourceid === backupID);
    
    backupObj.srcobj.getTokenCallback((token, expire) => {
      const { sourceid } = backupObj;
      apiMethod('backup_modify', {
        type: sourceid,
        action: 'start',
        now: 1
      }, (ret) => {
        HFN.message('Backup Restarted.');
        // snimp.refresh();
        // Popup.close();
        backupObj.is_stopped = false;
        backupObj.is_running = true;
        backupObj.is_paused = false;
        backupObj.is_aborted = false;
        backupObj.is_inactive = false;
        setBackupsState(backupStateCopy);
        closeModal();
      }); 
    });
  }

  const openStopModal = ({ backupID }) => {
    const sourceData = new SOURCES[backupID]();
    console.log("sourceData", sourceData, SOURCES, backupID);

    setModalSettings({
      isOpened: true,
      primaryClick: () => stopBackup({ backupID }),
      primaryText: __("Stop Backup"),
      secondaryClick: closeModal,
      secondaryText: __("Cancel"),
      title: __("Stop Backup") + ": " + sourceData.title,
      description: __("You are stopping the backup of") + ": " + sourceData.title,
      primaryColor: "red",
      onClose: closeModal
    });
  }

  const stopBackup = ({ backupID, successCallback }) => {
    const backupStateCopy = [ ...backupsState ];
    const backupObj = backupStateCopy.find(({ sourceid }) => sourceid === backupID);
    const { sourceid } = backupObj;

    apiMethod('backup_modify', {
      type: sourceid,
      action: 'stop'
    }, (ret) => {
      
      // snimp.refresh();
      // Popup.close();
      backupObj.is_running = false;
      backupObj.is_paused = false;
      backupObj.is_stopped = true;
      backupObj.is_aborted = false;
      backupObj.is_inactive = false;
      backupObj.next_execution = null;
      setBackupsState(backupStateCopy);

      if (successCallback) {
        successCallback();
      } else {
        HFN.message(__('Backup stopped.'));
        closeModal();
      }
    }); 
  };

  const openSetupModal = ({ backupID }) => {
    const sourceData = new SOURCES[backupID]();
    const backupObj = backupsState.find(({ sourceid }) => sourceid === backupID);

    const modalData = {
      isOpened: true,
      isLoading: true,
      primaryClick: () => setupBackup({ backupID }),
      primaryText: __("Set Backup"),
      secondaryClick: closeModal,
      secondaryText: __("Cancel"),
      title: "Set Backup: " + sourceData.title,
      description: "Backup account: no information",
      primaryColor: "green",
      onClose: closeModal
    }
    console.log("backupObj.srcobj.getCurrentAccount", sourceData, backupObj, sourceData.getCurrentAccount);
    if (backupObj.srcobj.getUserIdentity && backupID === "gdriven") {
      backupObj.srcobj.getUserIdentity((email) => {
        setModalSettings({ ...modalData, isLoading: false, description: "Backup account: " + email})
      });
    } else if (sourceData.getCurrentAccount && (backupID === "dbxn" || backupID === "dbxb")) {
      sourceData.getCurrentAccount(({ email }) => {
        setModalSettings({ ...modalData, isLoading: false, description: "Backup account: " + email})
			});
    } else {
      setModalSettings(modalData);
    }
  }

  const setupBackup = ({ backupID }) => {
    const backupStateCopy = [ ...backupsState ];
    const backupObj = backupStateCopy.find(({ sourceid }) => sourceid === backupID);
    const { sourceid } = backupObj;

    const source = new SOURCES[backupID]();
    backupObj.srcobj.getTokenCallback((token, expire) => {
      if (backupID === "gdriven" || backupID === "dbxn" || backupID === "dbxb") { //backup all files
        setupBackupSimple({ backupID, sourceid });
      } else { //backup specific folders
        SETUP_METHODS_MAP['setupBackup' + source.type]({ backupID, sourceid });
      }
    }, true);
  };

  const onSetupSuccess = ({ backupID }) => {
    const backupStateCopy = [ ...backupsState ];
    const backupObj = backupStateCopy.find(({ sourceid }) => sourceid === backupID);
    const { sourceid } = backupObj;

    backupObj.is_running = true;
    backupObj.is_paused = false;
    backupObj.is_stopped = false;
    backupObj.is_aborted = false;
    backupObj.is_inactive = false;

    setBackupsState(backupStateCopy);
    closeModal();
  }

  const setupBackupSimple = ({ backupID, sourceid }) => {
    HFN.apiMethod('backup_modify', {
      mode: 0, // all
      type: sourceid,
      action: 'start',
      now: 1
    }, (ret) => {
      HFN.message(__('Settings Saved.'));
      onSetupSuccess({ backupID });
      closeModal();
    });
  }

  const setupBackupFileStorage = ({ backupID, sourceid }) => {
    HFN.apiMethod('backup_modify', {
      mode: 0, // all
      type: sourceid,
      action: 'start',
      now: 1
    }, (ret) => {
      HFN.message(__('Settings Saved.'));
      onSetupSuccess({ backupID });
      closeModal();
    });
  }

  const SETUP_METHODS_MAP = {
    // setupBackupGoogleDrive,
    setupBackupFileStorage
  }

  const changeBackupStatus = ({ backupID }) => {
    const backupStateCopy = [ ...backupsState ];
    const backupObj = backupStateCopy.find(({ sourceid }) => sourceid === backupID);
    if (backupObj.is_stopped || backupObj.is_inactive || backupObj.type == "onetime") {
      backupObj.srcobj.getTokenCallback((token, expire) => {
        snimp.initStartBackup(backupObj.sourceid, () => {
          backupObj.is_stopped = false;
          backupObj.is_running = true;
          backupObj.is_paused = false;
          backupObj.is_aborted = false;
          backupObj.is_inactive = false;
          setBackupsState(backupStateCopy);
        });
      }, !backupObj.srcobj.token);
      // setupBackup({ backupID });
      // openSetupModal({ backupID })
    } else if (backupObj.is_aborted) {
      // openRestartModal({ backupID });
      backupObj.srcobj.getTokenCallback((token, expire) => {
        snimp.initRestartBackup(backupObj.sourceid, () => {
          backupObj.is_running = true;
          backupObj.is_paused = false;
          backupObj.is_stopped = false;
          backupObj.is_aborted = false;
          backupObj.is_inactive = false;
          setBackupsState(backupStateCopy);
        });
      }, !backupObj.srcobj.token);
    } else if (!backupObj.is_stopped && !backupObj.is_aborted && !backupObj.is_running && !backupObj.is_paused) {
      openStopModal({ backupID });
    } else if (!backupObj.is_stopped && !backupObj.is_running && !backupObj.is_paused && new Date(backupObj.finished).getTime() < new Date().getTime() - ( 30 * 60 * 1000 )) {
      backupObj.srcobj.getTokenCallback((token, expire) => {
        snimp.initRestartBackup(backupObj.sourceid, () => {
          backupObj.is_running = true;
          backupObj.is_paused = false;
          backupObj.is_stopped = false;
          backupObj.is_aborted = false;
          backupObj.is_inactive = false;
          setBackupsState(backupStateCopy);
        });
      }, !backupObj.srcobj.token);
    }
  }

  const updateBackupsState = () => {
    apiMethod('backup_list', {}, ({ backups }) => {
      let wait = [];
      const backupsStateCopy = [ ...backupsState ];

      if (!backups.length) {
        setIsBackupStateLoading(false);
        return;
      }

      backups.forEach(backupinfo => {
        const index = findIndex(backupsStateCopy, ({sourceid}) => sourceid === backupinfo.sourceid);
        backupsStateCopy[index] = { ...backupsStateCopy[index], is_inactive: false, ...backupinfo, sourceid: backupinfo.sourceid };

        if (backupinfo.backup_metadata) {
          ((backup) => {
            wait.push(apiMethod('listfolder', { folderid: backup.backup_metadata.folderid, recursive: 0, nofiles: 1 }, (ret) => {
              backup.backup_folder_name = ret.metadata.name;
            }, { 
              errorCallback: () => {
                backup.backup_metadata = false;
              },
              cacheTime: 0,
              forceFresh: true
            }));
          })(backupinfo);
        }
      })

      const onDone = () => {
        // snimp.initList(backupsStateCopy);
        setBackupsState(backupsStateCopy);
        setIsBackupStateLoading(false);
      }

      $.when.apply($, wait).then(onDone);
    });
  }

  const renderBackupsState = (backups) => {
    return backups.map(backup => (
      <ThirdPartyBackupBox
        {...backup}
        changeBackupStatus={changeBackupStatus}
        stopBackup={stopBackup}
        isOverquota={isOverquota}
        key={backup.sourceid}
        isBackupStateLoading={isBackupStateLoading}
      />
    ))
  }

  return (
    <React.Fragment>
      <Header>
        <PageTitle>{__("Backups")}</PageTitle>
        <SettingsButton href="#page=settings&settings=tab-apps">
          {__("Backup Settings")}
        </SettingsButton>
      </Header>
      {isOverquota ? (
        <TopMessage>
          <Componentify
            text={__("thirdparty_backups_overquota")}
            converters={[linkConverter, linkConverterV2]}
          />
        </TopMessage> 
      ) : null}
      {oneTimeBackups && oneTimeBackups.length ? (
        <Section>
          <SectionTitle>{__("thirdparty_backups_onetime", "One-time")}</SectionTitle>
          <SectionDescription>{__("thirdparty_backups_onetime_descr", "Import your files from other services to pCloud. You can make one-time backups once every 30 days.")}</SectionDescription>
          <Wrapper>
            {renderBackupsState(oneTimeBackups)}
            {!isPremium && !isBusiness ? <BackupPromoBox/> : null}
          </Wrapper>
        </Section>
      ) : null}
      {ongoingBackups && ongoingBackups.length ? (
        <Section>
          <SectionTitle>{__("thirdparty_backups_ongoing", "Ongoing")}</SectionTitle>
          <SectionDescription>{__("thirdparty_backups_ongoing_descr", "Create an automatic, ongoing backup from other services to pCloud.")}</SectionDescription>
          <Wrapper>
            {renderBackupsState(ongoingBackups)}
          </Wrapper>
        </Section>
      ) : null}
      <Footer>
        <FooterMessage>{__("backups_support_notice")}</FooterMessage>
      </Footer>
      <ActionModal {...modalSettings}/>
    </React.Fragment>
  )
}

export default ThirdPartyBackupsContainer;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
  align-items: stretch;
  @media(max-width: 768px) {
    background-color: #f6f6f6;
    padding: 30px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #e4e4e4;
  }

  @media(max-width: 400px) {
    padding: 30px 10px;
  }
`;

// const Overlay = styled.div`
//   display: inline-block;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   left: 0px;
//   top: 0px;
//   background-color: rgba(255, 255, 255, 0.5);
// `;

const TopMessage = styled.div`
  border-radius: 13.5px;
  border: solid 1px #fcf2d5;
  background-color: #fff8e0;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 18px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  
  a {
    text-decoration: none;
    color: #17bed0;
    font-weight: bold;
  }

  @media(max-width: 768px) {
    border-radius: 0px;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 0px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 13px;
  margin-top: 32px;
  border-bottom: 1px solid #f2f2f2;

  @media(max-width: 768px) {
    margin: 0px;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #e4e4e4;
  }
`;

const PageTitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;

const SettingsButton = styled.a`
  display: inline-block;
  min-width: 192px;
  box-sizing: border-box;
  /* padding-left: 25px; */
  background-color: #fff;
  text-transform: none;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  font-size: 13px;
  line-height: 32px;
  height: 32px;
  text-align: center;
  font-weight: normal;
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 7px 7px;
  background-image: url(${require("../../../root/img/backup/set.png")});
  ${hiDPI()} {
    background-image: url(${require("../../../root/img/backup/set@2x.png")});
  }
`;

const Section = styled.div`
  margin-bottom: 30px;

  @media(max-width: 768px) {
    padding: 0px 20px;
  }
`

const SectionTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  color: #000;
  margin-bottom: 4px;
`;

const SectionDescription = styled.div`
  font-size: 13px;
  text-align: left;
  color: #000;
  margin-bottom: 16px;
`;

const Footer = styled.div``;

const FooterMessage = styled.div` 
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #999999;

  @media(max-width: 768px) {
    padding: 0px 20px;
    box-sizing: border-box;
    margin-top: 20px;
    text-align: center;
  }
`;
