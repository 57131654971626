//@flow

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import reactStringReplace from "react-string-replace";
import Componentify from "react-componentify";
import { getPromoParam } from "@pcloud/web-utilities/dist/lib/ptr";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import {
  hrefSearch,
  rcookie,
  loadScript,
  open3DsecureIframe,
  on3DsecureError
} from "../../lib/utils";
import { planNames, plansQuota, testPeriods } from "../../lib/plansInfo";
import { ColorType } from "../../../web-shared/dataTypes";
import { signToCurrency } from "../../../web-shared/constants";
import { sendAnalyticsEvent } from "../../lib/sendAnalyticsEvent";

import Modal, { OvalModalClose } from "../../components/Modal";

import { PaymentLoader } from "./PaymentLoader";
import CreditCardBox from "./CreditCardBox";
import CreditCardFormTemplate from "./CreditCardFormTemplate";
import PrefilledCreditCardForm from "./PrefilledCreditCardForm";
import { linkConverter } from "../../lib/componentifyConverters";

type CreditCardModalProps = {
  planId: string,
  billingPeriod: "month" | "year" | "lifetime",
  color: ColorType,
  price: number,
  numberOfmembers?: number,
  showSuccessMessage?: () => void,
  analytics: {
    category: string,
    label: string
  },
  discount?: string,
  isBusiness?: boolean,
  endTrial?: boolean
};

const CreditCardModal = ({
  planId,
  billingPeriod,
  color,
  price,
  numberOfmembers = 0,
  discount,
  showSuccessMessage = () => {},
  analytics,
  isBusiness,
  endTrial = false
}: CreditCardModalProps) => {
  const userInfo = useSelector(({ user }) => user.userinfo);
  const currency = useSelector(({ user }) => user.userinfo.currency);
  const auth = useSelector(({ user }) => user.token);
  const [isOpen, setIsOpen] = useState(false);
  const [country, setCountry] = useState("US");
  const [paymentProvider, setPaymentProvider] = useState("stripe");
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(true);
  const [successLoading, setSuccessLoading] = useState(false);
  const [userPaymentSource, setUserPaymentSource] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const loaderElement = useRef(null);

  const promoParams = {
    label: getPromoParam("label"),
    channelid: getPromoParam("channelid")
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    apiMethod(
      "getip",
      {},
      ({ country }) => {
        setCountry(country);
      },
      {
        errorCallback: ({ error }) => {
          throw new Error(error);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (hrefSearch("ErrCode") === "0") {
      afterPaymentActions(hrefSearch("transactionid"));
      return;
    }

    setCart(planId);
    loadPaymentData();
    loadSubscribedProducts();
  }, []);

  useEffect(() => {
    if (paymentProvider === "stripe") {
      loadScript("https://js.stripe.com/v2/", () => {});
      loadScript("https://js.stripe.com/v3/", () => {});
    } else if (paymentProvider === "safecharge") {
      loadScript("https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js", () => {});
    }
  }, [paymentProvider]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const getPaymentSource = () => {
    return new Promise((resolve, reject) => {
      apiMethod(
        "listpaymentsources",
        { auth },
        (res: any) => {
          resolve(res);
        },
        {
          errorCallback: reject
        }
      );
    });
  };

  const getPaymentProvider = () => {
    return new Promise((resolve, reject) => {
      const params = { auth };

      if (rcookie("forcecard")) {
        params.card = rcookie("forcecard");
      }

      if (rcookie("forcecountry")) {
        params.country = rcookie("forcecountry");
      }

      apiMethod(
        "listpaymentmethods",
        params,
        (res: any) => {
          resolve(res);
        },
        {}
      );
    });
  };

  const loadPaymentData = () => {
    const paymentSourcePromise = getPaymentSource();
    const paymentProviderPromise = getPaymentProvider();

    Promise.all([paymentSourcePromise, paymentProviderPromise]).then(values => {
      const paymentSourceData = values[0];
      const paymentProviderData = values[1];
      let newProvider = "stripe";

      if (paymentProviderData.paymentmethods) {
        const paymentMethod = paymentProviderData.paymentmethods[0];
        newProvider = paymentMethod.method;
      }

      if (paymentSourceData.stripe || paymentSourceData.safecharge || paymentSourceData.paypal) {
        if (paymentSourceData.stripe) {
          newProvider = "stripe";
        } else if (paymentSourceData.safecharge) {
          newProvider = "safecharge";
        }
        setUserPaymentSource(paymentSourceData);
      }

      setPaymentProvider(newProvider);

      setTimeout(() => {
        setLoading(false);
      }, 300);
    });
  };

  const loadSubscribedProducts = () => {
    const { lastsubscription } = userInfo;

    if (lastsubscription && lastsubscription.status != "active") {
      return;
    }

    let choosenProducts = planId;
    choosenProducts = choosenProducts.map(productId => Number(productId));
    const otherProducts =
      lastsubscription &&
      lastsubscription.products.filter(subscribedProduct => {
        const choosenPremiumProducts = choosenProducts.filter(i => [1, 3, 14, 15].indexOf(i) != -1);
        const userChoosedPremiumProduct = !!choosenPremiumProducts.length;
        const userHasPremiumSubscription = [1, 3, 14, 15].indexOf(subscribedProduct) != -1;

        const skipPremium = !(userChoosedPremiumProduct && userHasPremiumSubscription);

        return choosenProducts.indexOf(planId) == -1 && skipPremium;
      });

    setCart(
      otherProducts && billingPeriod !== "lifetime" && billingPeriod !== "onetime"
        ? choosenProducts.concat(otherProducts)
        : choosenProducts
    );
  };

  const afterPaymentActions = useCallback(res => {
    const transactionID = res.transactionId || res.transactionid || res.id;
    const { label, category } = analytics;
    const analyticsParams = {
      category: "business",
      action: "purchase",
      label: promoParams.label || label,
      os: 4
    };

    const analyticsData = {
      revenue: price,
      currency: signToCurrency[currency] || "USD"
    };

    if (promoParams.channelid) {
      analyticsData.channelid = promoParams.channelid;
    }

    sendAnalyticsEvent(analyticsParams, analyticsData);

    if (gaAll && typeof gaAll === "function") {
      const itemsInfo = [
        {
          name: cart.map(planid => planNames[planid]).join(" + "),
          price: price,
          quantity: 1
        }
      ];

      // gaAll("event", "purchase", {
      //   transaction_id: transactionID,
      //   value: price,
      //   currency: signToCurrency[currency] || "USD",
      //   items: itemsInfo,
      //   shipping: "0",
      //   tax: "0"
      // });

      gaAll("event", "conversion", {
        'send_to': 'AW-946159937/3Uu5CKv9wo0YEMGClcMD',
        transaction_id: transactionID,
        value: price,
        currency: signToCurrency[currency] || "USD",
        items: itemsInfo,
        shipping: "0",
        tax: "0"
      });
    }

    if (typeof ga4 !== "undefined") {
      const ga4Items = cart.map(planID => {
        return {
          item_name: planNames[planID] + " " + testPeriods[billingPeriod],
          item_brand: "pCloud",
          item_category: testPeriods[billingPeriod],
          item_variant: plansQuota[planID] || "",
          affiliation: rcookie("ref"),
          coupon: discount || "",
          price: price,
          currency: signToCurrency[currency] || "USD",
          quantity: 1
        }
      });
      
      ga4({
        eventname: "purchase",
        ecommerce: {
          currency: signToCurrency[currency] || "USD",
          value: price,
          transaction_id: transactionID,
          payment_type: view === "card" ? cardProvider : view,
          items: ga4Items
        }
      })
    }

    if (fbq && typeof fbq == "function") {
      fbq("track", "Purchase", {
        value: price,
        currency: signToCurrency[currency] || "USD"
      });
    }

    if (sessionStorage.pCloud) {
      sessionStorage.removeItem("pCloud");
    }

    showSuccessMessage();
    setIsOpen(false);
  });

  const renderView = () => {
    if (loading) {
      return (
        <LoaderWrapper>
          <PaymentLoader ref={loaderElement} className="fadein" />
        </LoaderWrapper>
      );
    } else {
      let creditCardPaymentSource = null;

      if (userPaymentSource && userPaymentSource.stripe) {
        const stripeSources = userPaymentSource.stripe.sources
        creditCardPaymentSource = stripeSources[stripeSources.length - 1];
      } else if (userPaymentSource && userPaymentSource.safecharge) {
        const sfSources = userPaymentSource.safecharge.sources
        creditCardPaymentSource = sfSources[sfSources.length - 1];
      }

      return (
        <CreditCardBox
          auth={auth}
          planId={cart}
          period={billingPeriod}
          members={numberOfmembers}
          discount={discount}
          provider={paymentProvider}
          billingAddress={{
            email: userInfo.email,
            country: country.toUpperCase()
          }}
          RenderForm={CreditCardFormTemplate}
          RenderPrefilledForm={PrefilledCreditCardForm}
          userPaymentSource={creditCardPaymentSource}
          afterPaymentActions={afterPaymentActions}
          showLoading={setSuccessLoading}
          open3DsecureIframe={open3DsecureIframe}
          on3DsecureError={on3DsecureError}
          color={color}
          price={price}
          currency={currency}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isBusiness={isBusiness}
          endTrial={endTrial}
        />
      );
    }
  };

  const renderLoader = () => {
    return (
      <React.Fragment>
        <div
          className="payment_wrap safecharge_wrap"
          data-payment-box="safecharge"
          style={{ display: "none" }}
        >
          <div className="inner">
            <div className="vertical_align">
              <div className="spinner" />
              <div className="sf_logo_wrap">
                <div className="sf_logo_wrap_inner" />
              </div>
            </div>
          </div>
        </div>
        <LoaderContainer>
          <Loader />
          <LoaderText>
            {__("processing_payment", "Processing your payment, just a second...")}
          </LoaderText>
        </LoaderContainer>
      </React.Fragment>
    );
  };

  const renderCreditCard = () => {
    return (
      <React.Fragment>
        <AlignContainer>
          <FormWrapper>{renderView()}</FormWrapper>
        </AlignContainer>
        <DisclaimerWrapper>
          <div className="stored_credentials">
            {reactStringReplace(__("stored_credentials_message"), /(%here%)/g, (match, i) => (
              <span
                className="inline-button"
                onClick={() => {
                  window.open("https://www.pcloud.com/terms_and_conditions.html", "_blank");
                }}
              >
                {__("stored_credentials_here_parameter")}
              </span>
            ))}
          </div>
          {billingPeriod !== "lifetime" && billingPeriod !== "onetime" ? (
            <span className="auto_renewal">
              <Componentify
                text={__("autorenew_policy_message_componentify")}
                converters={[linkConverter]}
              />
            </span>
          ) : null}

          {paymentProvider === "safecharge" ? (
            <span className="safecharge_disclaimer">
              <div>
                {__("checkout_safecharge_terms_1")}
                {__("checkout_safecharge_terms_2")}
              </div>
              <a
                href="https://www.safecharge.com/users-terms-of-use-safecharge-digital/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>{" "}
              |
              <a
                href="https://www.safecharge.com/privacy-and-data-protection-digital/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          ) : null}
          <div className="address">pCloud International AG | 74 Zugerstrasse Str, 6340 Baar, Switzerland</div>
        </DisclaimerWrapper>
      </React.Fragment>
    );
  };

  return (
    <Modal style={{ width: "640px" }} opened={isOpen} onClose={handleClose}>
      <OvalModalClose onClick={handleClose} />
      <Wrapper style={{ width: "640px" }} className="paymentview_wrapper">
        {successLoading ? renderLoader() : renderCreditCard()}
      </Wrapper>
    </Modal>
  );
};

export default CreditCardModal;

const Wrapper = styled.div`
  @media (max-width: 768px) {
    padding: 20px;
    background-color: #ffffff;
  }
`;

const AlignContainer = styled.div`
  box-shadow: 2.1px 2.1px 5px 0 rgba(0, 0, 0, 0.01);
  background-color: #ffffff;
  min-height: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 26px;
  box-sizing: border-box;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  @media (max-width: 768px) {
    padding: 0px;
    min-height: 0px;
    border-radius: 0px;
  }
`;

const LoaderWrapper = styled.div`
  position: relative;
  min-height: 354px;
`;

const FormWrapper = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;

  .responsive-wrap {
    width: 100%;
  }
`;

const DisclaimerWrapper = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  background-color: #fafafa;
  border-top: 1px solid #f3f3f3;
  padding: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

  .address {
    font-weight: bold;
  }

  .stored_credentials {
    margin-bottom: 20px;

    .inline-button {
      font-weight: bold;
      cursor: pointer;
    }
  }
`;

const Pulsate = keyframes`
  0% {transform: scale(0.1, 0.1); opacity: 0;}
  50% {opacity: 1;}
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.2, 1.2);
    transform: translateZ(0);
    transform: scale(1.0, 1.0);
    opacity: 0;
    }
`;

const Loader = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid #20bed6;
  border-radius: 30px;
  animation: ${Pulsate} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`;

const LoaderText = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  font-weight: 500;
  margin-top: 35px;
`;

const LoaderContainer = styled.div`
  min-height: 410px;
  border-radius: 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 640px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 40px;
  box-sizing: border-box;
`;

const PaymentMethodTitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  font-weight: 500;
  padding-top: 40px;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;
