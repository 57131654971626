//@flow

import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";

import { TimerTemplate } from "./TimerTemplate";
import TimerContainer from "./TimerContainer";

type PromoStickyBarV2Props = {
  bgcolor: string,
  title: string,
  titlecolor: string,
  btntext: string,
  btnlink: string,
  btnbackgroundstart: string,
  btnbackgroundend: string,
  btnfontcolor: string,
  timerStartTime?: string | number | boolean,
  timerEndTime?: string | number | boolean,
  timerbackgroundcolor?: string,
  timerfontcolor?: string,
  useLocalTime?: boolean,
  onActionBtnClick: () => void,
  onMountStickyBar: () => void,
}

const PromoStickyBarV2 = ({
  bgcolor,
  title,
  titlecolor,
  btntext,
  btnlink,
  btnbackgroundstart,
  btnbackgroundend,
  btnfontcolor,
  timerStartTime = null,
  timerEndTime = null,
  timerbackgroundcolor,
  timerfontcolor,
  useLocalTime = false,
  onActionBtnClick,
  onMountStickyBar,
} : PromoStickyBarV2Props) => {
  const stickyBarWrapperRef = useRef(null);
  const stickyBarHeightObserver = useRef(null);
  const [ stickyBarSpacerHeight, setStickyBarSpacerDivHeight ] = useState(0);

  useEffect(() => {
    onMountStickyBar();
  }, []);

  const setUpdateStickyBarSpacerHeightListener = () => {
    stickyBarWrapperRef.current = document.getElementById("sticky-bar-wrapper");

    if (!stickyBarWrapperRef.current) {
      return;
    }

    stickyBarHeightObserver.current = new ResizeObserver(() => {
      // Update spacer height.
      const stickyBarHeight = Math.ceil(stickyBarWrapperRef.current.getBoundingClientRect().height);
      if (stickyBarHeight > 0) {
        setStickyBarSpacerDivHeight(stickyBarHeight);
      }
    });
  
    stickyBarHeightObserver.current.observe(stickyBarWrapperRef.current);
  };

  const unsetUpdateStickyBarSpacerHeightListenerOnUnmount = () => {
    if (stickyBarHeightObserver.current !== null) {
      stickyBarHeightObserver.current.disconnect();
      stickyBarHeightObserver.current = null;
    }
  };

  useEffect(() => {
      setUpdateStickyBarSpacerHeightListener();

    // Cleanup the added element.
    return unsetUpdateStickyBarSpacerHeightListenerOnUnmount;
  }, []);

  const getTimerData = () => {
    if (!timerStartTime || !timerEndTime) {
      return false;
    }

    const timer = {
      start: new Date(timerStartTime.replace(" ", "T")).getTime(),
      end: new Date(timerEndTime.replace(" ", "T")).getTime()
    };

    if (!useLocalTime) {
      const offset = new Date().getTimezoneOffset() * 60 * 1000;
      timer.start += offset * -1;
      timer.end += offset * -1;
    }

    return timer;
  };

  const timerData = getTimerData();
  const timeNow = new Date().getTime();
  const shownTimer = !(!timerData || timerData.start > timeNow || timerData.end < timeNow);

  const renderTimer = () => {
    if (!shownTimer) {
      return null;
    }

    return (
      <TimerWrapper>
        <TimerContainer
          timeExpire={timerData.end}
          timeExpireCallback={() => {}}
          showLabels={false}
          renderTimer={TimerTemplate}
          digitsColor={timerfontcolor}
          textColor={timerfontcolor}
          timerColor={timerbackgroundcolor}
          size="mediumV2"
          foldableDigits={false}
          showTimerDigitCouplesAsOneOnMobile={true}
        ></TimerContainer>
      </TimerWrapper>
    );
  };

  return (
    <Wrapper height="64px" bgcolor={bgcolor} id="sticky-bar-wrapper">
        <WrapperInner >
          <Text color={titlecolor} shownTimer={shownTimer} dangerouslySetInnerHTML={{__html: title}}></Text>
          <WrapperTimerAndButton>
            {renderTimer()}
            <ButtonLink shownTimer={shownTimer} backgroundColorStart={btnbackgroundstart} backgroundColorEnd={btnbackgroundend} color={btnfontcolor} href={btnlink} onClick={onActionBtnClick}>
              {btntext}
            </ButtonLink>
          </WrapperTimerAndButton>
        </WrapperInner>

        {createPortal(
          // On mobile we need some spacer element appended to the body. 
          // Otherwise content on the bottom of the page will remain covered by the sticky bar.
          <StickyBarSpacerDivOnMobile height={stickyBarSpacerHeight}></StickyBarSpacerDivOnMobile>,
          document.body
        )}
    </Wrapper>
  )
}

export default PromoStickyBarV2;

const Wrapper = styled.div`
  width: 100%;
  height: ${props => props.height ? props.height : '64px'};
  background-color: ${props => props.bgcolor ? "#" + props.bgcolor : "#000000"};
  & > div {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.height ? props.height : '100%'};

    @media (max-width: 768px) {
      display: block;
      text-align: center;
      height: auto;
      margin: 16px 0;
    }
  }

  .timer-label {
    display: none;
  }

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    height: auto;

    .timer-label {
      display: block;
    }
  }
`;

const WrapperInner = styled.div`
  font-family: Roboto;
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: ${props => props.color ? "#" + props.color : "#ffffff"};
`;

const Text = styled.span`
  color: ${props => props.color ? "#" + props.color : "inherit"};
  margin: ${props => props.shownTimer ? "0 20px": "0 10px 0 20px"};
  @media (min-width: 769px) and (max-width: 1200px) {
    margin: ${props => props.shownTimer ? "0 16px": "0 8px 0 16px"};
    font-size: ${props => props.shownTimer ? "min(1.7vw, 19px)": "min(1.7vw, 19px)"};
  }
`;

const WrapperTimerAndButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin: 6px 8px;
  }
`;

// background-color: ${props => props.backgroundColor ? "#" + props.backgroundColor : "#ffffff"};
const ButtonLink = styled.a`
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to bottom, ${props => props.backgroundColorStart ? "#" + props.backgroundColorStart : "#ffffff"}, ${props => props.backgroundColorEnd ? "#" + props.backgroundColorEnd : "#ffffff"});
  margin: ${props => props.shownTimer ? "10px 20px": "10px 20px 10px 10px"};
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 8px;
  padding: 0 20px;
  letter-spacing: -0.15px;
  text-decoration: none;
  color: ${props => props.color ? "#" + props.color: "#2c2c2c"};
  white-space: nowrap;
  text-align: center;

  @media (min-width: 769px) and (max-width: 1200px) {
    margin: ${props => props.shownTimer ? "0 16px": "0 16px 0 8px"};
    height: auto;
    line-height: ${props => props.shownTimer ? "min(4.2vw, 40px)": "min(5.2vw, 40px)"};
    font-size: ${props => props.shownTimer ? "min(1.7vw, 15px)": "min(1.7vw, 15px)"};
  }

  @media (max-width: 768px) {
    border-radius: 18px;
    margin: 6px 8px;
  }
`;

const TimerWrapper = styled.div`
    @media (min-width: 769px) and (max-width: 1200px) {
      .timer-sep {
        line-height: min(4.2vw, 40px);
        padding: 0;
        font-size: min(1.7vw, 22px);
      }

      .timer-card {
        line-height: min(4.2vw, 40px);
        font-size: min(1.7vw, 22px);
        width: inherit;
        height: inherit;
        padding: 0 10px;
      }
    }

    @media (max-width: 768px) {
      margin: 6px 8px;

      .cards-wrapper {
        border-radius: 18px;
        padding: 0 12px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .values-wrapper {
        font-size: 16px;
        line-height: 16px;
      }
      .timer-card {
        font-size: inherit;
        line-height: inherit;
      }

      .timer-label {
        margin-top: 4px;
        font-size: 8px;
        line-height: 8px;
      }

      .timer-sep {
        margin: 0 3px;
      }
    }
`;

const StickyBarSpacerDivOnMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
      display: block;
      height: ${props => props.height ? props.height: 0}px;
  }
`;