// @flow

import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle
} from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import styled, { keyframes } from "styled-components";
import { List, Map } from "immutable";

import { __ } from "../../lib/translate";
import { filterAndSort, getFolderIds } from "../../lib/utils";
import { errorKeys } from "../../lib/errors";
import apiMethod from "../../api/apiMethod";
import getCryptoRoots from "../../api/cryptoroots";
import { ROOT_FOLDER_ID } from "./constants";

import { parseSelectedItem } from "./utils";
import type { selectedItemType } from "./utils";

import * as Style from "../Modals/styledComponents";
import CreateNewFolderInput from "./CreateNewFolderInput";
import InputStyledCheckbox from "../InputStyledCheckbox";
import Button from "../ButtonDefault";
import ItemsList from "./ItemsList";
import Navigation from "./Navigation";

type Folder = {
  folderid: number,
  name: string,
  contents: null | List<any>
};

type Opts = {
  isFolderSelectionOnly: boolean,
  showFoldersOnly: boolean,
  canCreateFolders: boolean,
  shouldRenderCheckbox: boolean,
  buttonText: string,
  checkboxName: string,
  isCrypto: boolean
};

type PickerProps = {
  folder: Folder,
  opts: Opts,
  onPick: () => void,
  onCancel: () => void
};

const Picker = forwardRef(
  (
    {
      folder,
      opts: {
        isFolderSelectionOnly = true,
        showFoldersOnly = false,
        canCreateFolders = true,
        shouldRenderCheckbox = false,
        checkboxName = "Open Folder",
        buttonText = "Choose",
        isCrypto = false
      },
      onPick = () => { },
      onCancel = () => { }
    }: PickerProps,
    ref
  ) => {
    const { folderid = ROOT_FOLDER_ID, name = "/", contents = null, ...rest } = folder;
    const userInfo = useSelector(({ user }) => user.userinfo);
    const account = useSelector(({ user }) => user.userinfo.account || {});
    const { cryptov2isactive } = userInfo;
    const { owner: isOwner = false } = account;
    const isCryptoV2User = cryptov2isactive && !isOwner;
    const [createdFolderId, setCreatedFolderId] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(folderid.toString());
    const [cryptoKey, setCryptoKey] = useState("");
    const [shouldDisableCreateFolder, setShouldDisableCreateFolder] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [path, setPath] = useState(List());
    const [loader, setLoader] = useState(false);
    const [folders, setFolders] = useState(Map());
    const [itemRef, setItemRef] = useState(null);

    useEffect(() => {
      apiMethod(
        "getbreadcrumb",
        { folderid: folderid, getkeys: 1 },
        ({ breadcrumb }) => {
          console.log("getbreadcrumb", breadcrumb);
          let initialPath = List();
          let pathFoldersData = folders;
          breadcrumb.forEach(({ metadata }, index) => {
            const { folderid, encrypted, ...rest } = metadata;
            const skipFisrt = isCrypto && !isCryptoV2User && index === 0;
            const skipSecond = isCrypto && isCryptoV2User && index === 1;

            if (skipFisrt || skipSecond || (isCrypto && !isCryptoV2User && !encrypted)) {
              return;
            } else {
              if (!pathFoldersData.has(folderid.toString())) {
                pathFoldersData = pathFoldersData.set(folderid.toString(), {
                  encrypted: encrypted || (isCrypto && isCryptoV2User && index === 0),
                  folderid: folderid,
                  contents: null,
                  ...rest
                });
              }
              initialPath = initialPath.set(initialPath.size, {
                encrypted: encrypted || (isCrypto && isCryptoV2User && index === 0),
                folderid: folderid,
                contents: null,
                ...rest
              });
            }
          });

          setFolders(pathFoldersData);
          setPath(initialPath);
        },
        {
          forceFresh: true
        }
      );
    }, []); //[])

    useEffect(() => {
      const unsub = HFN.diffHandler.listen(events => {
        const createEvent = events.filter(
          ev => ev.event === "createfolder" || ev.event === "createfile"
        );
        const modifyEvent = events.filter(
          ev => ev.event === "modifyfolder" || ev.event === "modifyfile"
        );
        const deleteEvent = events.filter(
          ev => ev.event === "deletefolder" || ev.event === "deletefile"
        );

        console.log("Picker subs");
        // event create
        if (createEvent.length) {
          createEvent.forEach(({ metadata }) => {
            const { folderid, name, parentfolderid, isfolder, contents = null, ...rest } = metadata;

            if (folders.has(parentfolderid.toString())) {
              const updatedItems = folders.updateIn(
                [parentfolderid.toString(), "contents"],
                contents => {
                  const newItems = contents.set(contents.size, metadata);
                  return filterAndSort(newItems, isCrypto);
                }
              );

              setFolders(updatedItems);

              if (isfolder && folderid == createdFolderId) {
                itemRef && itemRef.current.scrollIntoView({ behavior: "smooth" });
                onItemClick(folderid.toString(), true);
              }
            } else if (isfolder) {
              setFolders(
                folders.set(folderid.toString(), {
                  contents: contents ? List(filterAndSort(contents, isCrypto)) : null,
                  ...rest
                })
              );
            }
          });
        }

        // event modify
        if (modifyEvent.length) {
          modifyEvent.forEach(({ metadata }) => {
            const { id, parentfolderid } = metadata;

            if (folders.has(parentfolderid.toString())) {
              const updatedItems = folders.updateIn(
                [parentfolderid.toString(), "contents"],
                contents => {
                  const itemIndex = contents.findIndex(item => item.id === id);
                  contents.filter(el => el.id !== id);
                  return contents.set(itemIndex, metadata);
                }
              );

              setFolders(updatedItems);
            }
          });
        }

        // event delete
        if (deleteEvent.length) {
          let updatedItems = folders;
          deleteEvent.forEach(({ metadata }) => {
            const { id, parentfolderid } = metadata;

            if (folders.has(parentfolderid.toString())) {
              updatedItems = updatedItems.updateIn(
                [parentfolderid.toString(), "contents"],
                contents => {
                  const itemIndex = contents.findIndex(item => {
                    return item.id === id;
                  });

                  if (itemIndex !== -1) {
                    return contents.delete(itemIndex);
                  } else {
                    return contents;
                  }
                }
              );
            }
          });
          setFolders(updatedItems);
        }
      });

      return () => {
        unsub();
        console.log("Picker subs");
      };
    }, [folders, isCrypto, createdFolderId]); // [folders, isCrypto, createdFolderId]

    useEffect(() => {
      const { folderid } = folder;
      const currentFolderData = folders.get(folderid.toString()) || {};
      const { contents = null } = currentFolderData;

      if (contents === null) {
        fetchFolder(folderid);
      }
    }, [folders]); //[folders]

    useEffect(() => {
      const currentFolderData = folders.get(selectedItemId.toString());

      if (!currentFolderData) {
        return;
      }
      const isCryptoV2Root = isCryptoV2User && currentFolderData.virtualfolder;
      const canCreate =
        !isCryptoV2Root &&
        canCreateFolders &&
        Perm.canCreate(currentFolderData) &&
        !currentFolderData.isbackupdevicelist &&
        !currentFolderData.isbackupdevice;

      setShouldDisableCreateFolder(canCreate);
    }, [path, folders]); // [path, folders]

    useImperativeHandle(ref, () => ({
      onChooseButtonClick: onChooseButtonClick
    }));

    const fetchFolderData = useCallback(
      ({ folderid = ROOT_FOLDER_ID, showFiles = true }) => {
        let params = {
          folderid: folderid,
          recursive: 0,
          nofiles: 1,
          iconformat: "id"
        };
        let method = "listfolder";

        if (showFiles) {
          delete params.nofiles;
        }

        if (isCrypto) {
          params.getkey = 1;
          params.getpublicfolderlink = 1;
        }
        const cacheid = HFN.cache.cacheid("listfolder", "list", folderid, "default");
        const cachedData = HFN.cache.get(cacheid);

        console.log("cacheid", cacheid);
        console.log("cacheid", cachedData);

        if (cachedData) {
          return new Promise((resolve, reject) => {
            resolve({ metadata: cachedData });
          });
        } else {
          return new Promise((resolve, reject) => {
            apiMethod(
              method,
              params,
              ({ metadata, key }) => {
                HFN.appendMetadata(metadata);
                HFN.cacheTree(metadata, false, HFN.data.fflookup);

                resolve({ metadata, key });
              },
              {
                errorCallback: error => {
                  reject(error);
                },
                forceFresh: true
              }
            );
          });
        }
      },
      [] // []
    );

    const getCurrentFolderId = useCallback((): string => {
      if (path.last()) {
        return path.last().folderid.toString();
      } else {
        return ROOT_FOLDER_ID;
      }
    }, [path]); // [path]

    const getSelectedItem = (): selectedItemType | null => {
      const currentFolderId = getCurrentFolderId();
      const contents = folders.getIn([currentFolderId, "contents"], null);

      if (selectedItemId == currentFolderId) {
        return {
          itemId: selectedItemId,
          isfolder: true,
          name: folders.getIn([selectedItemId, "name"], "")
        };
      } else if (contents !== null) {
        return parseSelectedItem(contents.find(item => item.folderid == selectedItemId));
      }

      return null;
    };

    const fetchFolder = useCallback(
      (id, shouldSetPath = false) => {
        const currentItem = folders.get(id.toString());
        const hasContents = currentItem ? currentItem.contents : null;

        if (isCrypto && isCryptoV2User && id == ROOT_FOLDER_ID && hasContents === null) {
          setLoader(true);
          getCryptoRoots({
            onSuccess: virtualFolder => {
              const { folderid, contents, ...rest } = virtualFolder;
              setFolders(
                folders.set(folderid.toString(), {
                  contents: contents ? List(filterAndSort(contents, isCrypto)) : null,
                  ...rest
                })
              );
              setLoader(false);
            }
          });
        } else if (hasContents === null) {
          setLoader(true);
          fetchFolderData({ folderid: +id })
            .then(({ metadata, key }) => {
              const { contents = null, ...rest } = metadata;

              setCryptoKey(key || "");
              setFolders(
                folders.set(id.toString(), {
                  contents: contents ? List(filterAndSort(contents, isCrypto)) : null,
                  ...rest
                })
              );

              if (isCrypto) {
                const folderIds = getFolderIds(metadata);

                if (folderIds.length) {
                  apiMethod(
                    "crypto_getfolderskey",
                    { folderids: folderIds.join(",") },
                    function (ret) {
                      let keys = [];
                      for (let key in ret.keys) {
                        keys["d" + key] = ret.keys[key];
                      }

                      pCloudCrypto.saveKeys(keys, function (ok) {
                        setLoader(false);
                      });
                    },
                    { type: "post" }
                  );
                }
              } else {
                setLoader(false);
              }

              if (shouldSetPath) {
                setPath(path.set(path.size, metadata));
              }
            })
            .catch(res => {
              const { error, result } = res;
              setLoader(false);
              if (errorKeys[result]) {
                HFN.message(__(errorKeys[result]), "error");
              } else {
                HFN.message(__("something_went_wrong_refresh_and_try_again"), "error");
                throw new Error(res);
              }
            });
        } else {
          if (shouldSetPath && currentItem) {
            setPath(path.set(path.size, currentItem));
          }
        }
      },
      [folders, isCrypto, fetchFolderData, path] // [folders, isCrypto, fetchFolderData, path]
    );

    const getPathToFolder = (folderId: string) => {
      const indexOfCurrentId = path.map(item => item.folderid).indexOf(folderId);
      return path.slice(0, indexOfCurrentId + 1);
    };

    const selectItem = (itemId: string) => {
      if (itemId !== selectedItemId) {
        setSelectedItemId(itemId);
      }
    };

    const onFolderDoubleClick = (folderId: string, name: string) => {
      console.log("ITEM on click", { folderId, name });
      fetchFolder(folderId, true);
      setSelectedItemId(folderId);
    };

    const onFileDoubleClick = () => {
      if (!isFolderSelectionOnly) {
        onChooseButtonClick();
      }
    };

    const onItemClick = useCallback((itemId: string, isfolder: boolean) => {
      if (isfolder || !isFolderSelectionOnly) {
        selectItem(itemId);
      }
    });

    const onItemDoubleClick = (isfolder: boolean, itemId: string, name: string) => {
      console.log("onItemDoubleClick", { isfolder, itemId, name });
      if (isfolder) {
        onFolderDoubleClick(itemId, name);
      } else {
        onFileDoubleClick();
      }
    };

    const onChooseButtonClick = useCallback(() => {
      const itemData = getSelectedItem();
      const params = { data: itemData };

      if (isCrypto && isCryptoV2User && itemData.itemId == ROOT_FOLDER_ID) {
        return;
      }

      if (shouldRenderCheckbox) {
        params.isChecked = isChecked;
      }

      if (cryptoKey) {
        params.cryptoKey = cryptoKey;
      }

      onPick(params);
    });

    const onNavigationClick = (folderId: string) => {
      setPath(getPathToFolder(folderId));
      fetchFolder(folderId);
      setSelectedItemId(folderId);
    };

    const renderHeader = () => {
      return (
        <Header key="header">
          <Navigation path={path} onNameClick={onNavigationClick} />
        </Header>
      );
    };

    const renderItems = (contents: any) => {
      return (
        <ItemsWrapper>
          {contents === null || loader ? (
            <Loader />
          ) : (
            <ItemsList
              createdFolderId={createdFolderId}
              isFolderSelectionOnly={isFolderSelectionOnly}
              selectedItemId={selectedItemId}
              contents={contents}
              onItemClick={onItemClick}
              onItemDoubleClick={onItemDoubleClick}
              getRef={setItemRef}
            />
          )}
        </ItemsWrapper>
      );
    };

    const renderCheckbox = () => {
      return (
        <Style.CheckboxWrapper>
          <Style.CheckboxLabel for="open">
            <InputStyledCheckbox
              id="open"
              name="checkbox"
              size="small"
              checked={isChecked}
              onChange={e => setIsChecked(e.target.checked)}
            />
            <Style.CheckboxLabelText>{__(checkboxName)}</Style.CheckboxLabelText>
          </Style.CheckboxLabel>
        </Style.CheckboxWrapper>
      );
    };

    const currentFolderId = getCurrentFolderId();
    const currentFolderData = folders.get(currentFolderId);
    const currentItems = folders.getIn([currentFolderId, "contents"], List());
    const isMoveButtonDisabled =
      isCryptoV2User && currentFolderData && currentFolderData.virtualfolder;

    return (
      <React.Fragment>
        <PickerWrapper>
          {renderHeader()}
          <Body key="body">
            {renderItems(currentItems)}
            <CreateNewFolderInput
              currentFolder={currentFolderData}
              shouldDisableCreateFolder={shouldDisableCreateFolder}
              onCreateFolderSuccess={setCreatedFolderId}
            />
          </Body>
        </PickerWrapper>
        {shouldRenderCheckbox ? renderCheckbox() : null}
        <Style.Footer>
          <Button
            color="lightgray4"
            style={{
              marginRight: "5px"
            }}
            onClick={onCancel}
          >
            {__("Cancel")}
          </Button>
          <Button
            data-tip
            data-for="move-button-disabled"
            color="cyan"
            disabled={isMoveButtonDisabled}
            style={{
              marginLeft: "5px"
            }}
            disabledColor="#dddddd"
            onClick={onChooseButtonClick}
          >
            {__(buttonText)}
          </Button>
          {isMoveButtonDisabled ? (
            <ReactTooltip id="move-button-disabled" effect="solid" multiline>
              {__("Can't write in this folder.")}
            </ReactTooltip>
          ) : null}
        </Style.Footer>
      </React.Fragment>
    );
  }
);

export default Picker;

const Header = styled.header`
  font-size: 15px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const Body = styled.div`
  font-size: 15px;
  font-weight: normal;
  position: relative;
  box-sizing: border-box;
  background: #fff;
  height: 220px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const Pulsate = keyframes`
  0% {transform: scale(0.1, 0.1); opacity: 0;}
  50% {opacity: 1;}
  100% {transform: scale(1.2, 1.2); opacity: 0;}
`;

const Loader = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  border: 4px solid #20bed6;
  border-radius: 30px;
  animation: ${Pulsate} 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`;

const PickerWrapper = styled.div`
  border: 1px solid #eee;
  border-radius: 5px;
`;

const ItemsWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
  background: #fff;
  height: 180px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    transition: background 200ms ease-in-out;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
