import { useState, useEffect, useMemo, useCallback } from 'react';
import fileUploadService from '../services/fileUploadService';
import { v4 as uuidv4 } from 'uuid';
import { UPLOAD_STATUS, MAX_CONCURRENT_FOLDER_UPLOADS, MAX_FAILED_UPLOADS_RETRIES, UPLOAD_OPTIONS, FILE_SIZE_FOR_CHUNKS, CRYPTOLOCKED_PAGE, UPLOAD_TAB, DEFAULT_PASTE_ITEM_NAME } from "../constants";
import { chunkArray, fibonacci, delay } from '../../../lib/utils';
import { getParentFolderPathFromArrayOfPaths, getUniqueFolders, uniqFilename, fileExistsCache, filterFilesByTab, shouldNotRetryForError, getFormattedPasteName } from '../helpers';

const useFileUpload = ({ opts }) => {
  const [uploadQueue, setUploadQueue] = useState([]);
  const [itemsForUpload, setItemsForUpload] = useState([]);
  const [abortControllers, setAbortControllers] = useState(new Map());
  const [isUploading, setIsUploading] = useState(false);
  const [failedFolderError, setFailedFolderError] = useState(0);
  const [failedItems, setFailedItems] = useState([]);
  const [uploadQueueExisting, setUploadQueueExisting] = useState([]);
  const [_, setItemsForUploadExisting] = useState([]);
  const [selectedOptionMap, setSelectedOptionMap] = useState(new Map());
  const [activeTab, setActiveTab] = useState(0);
  const [filteredUploadQueue, setFilteredUploadQueue] = useState([]);
  const [tabItemsLength, setTabItemsLength] = useState({
    [UPLOAD_TAB.ALL]: 0,
    [UPLOAD_TAB.ACTIVE]: 0,
    [UPLOAD_TAB.COMPLETED]: 0,
    [UPLOAD_TAB.FAILED]: 0,
  });
  const [isUploadRemoteModalOpen, setIsUploadRemoteModalOpen] = useState(false);
  // pasted item name
  const [isUploadPasteModalOpen, setIsUploadPasteModalOpen] = useState(false);
  const [finishedFolderItemToUpdate, setFinishedFolderItemToUpdate] = useState()

  // ensures that new items start to upload after previous items have been uploaded
  useEffect(() => {
    if (!isUploading && itemsForUpload.length > 0) {
      processItemsUpload(itemsForUpload);
      setItemsForUpload([]);
    }
  }, [isUploading])

  // Process and ensure existence of all folders before file uploads
  const processItemsUpload = async (items, abortControllersMap) => {
    let activeItemUploadIndex = 0;

    const uploadFiles = (uploadItem, foldersObject, signal) => {
      let { id, files, folderidCurrent, overwrite, renameifexists, skip, encrypted } = uploadItem;
      let ensureFoldersCalled = false;
      let activeUploadIndex = 0;
      let isAborted = false;

      // function params are for retries only
      const uploadFileWithRetry = async (attempt = 1, activeUploadIndexInRetry = undefined) => {
        const isDlink = HFN.config.isDlink();
        const activeItemUploadIndexExists = activeUploadIndexInRetry !== undefined;
        const currentActiveUploadIndex = activeItemUploadIndexExists ? activeUploadIndexInRetry : activeUploadIndex;
        const fileObj = activeItemUploadIndexExists ? files[activeUploadIndexInRetry] : files[activeUploadIndex];

        // stop recursive calls if there are no more files
        if (!fileObj) {
          return;
        }
        if (!activeItemUploadIndexExists) {
          ++activeUploadIndex;
        }

        // check if file exists
        // if option already selected, do not check
        if (!foldersObject) {
          if (skip) {
            abortUploadAction(id);
            ensureFoldersExist();
            return;
          } else if (!renameifexists && !overwrite) {
            const fileExists = fileExistsCache(folderidCurrent, fileObj.name, false);
            if (fileExists) {
              handleExistingUpload(uploadItem);
              ensureFoldersExist();
              return
            }
          }
        }

        const folderid = foldersObject ? foldersObject[getParentFolderPathFromArrayOfPaths(fileObj.webkitRelativePath.split('/'))] : folderidCurrent;

        const onProgress = (progress, total) => {
          if (total) {
            updateFileInQueue(id, { progress, size: total });
          } else {
            updateFileInQueue(id, { progress });
          }
        };

        const onUploadComplete = (data) => {
          if (!foldersObject) {
            ensureFoldersExist();
            if (data.name && data.size) {
              updateFileInQueue(id, { status: UPLOAD_STATUS.FADING_ACTIVE_COMPLETED, progress: 100, name: data.name, size: data.size, fileIdCreated: data.fileIdCreated });
            } else {
              updateFileInQueue(id, { status: UPLOAD_STATUS.FADING_ACTIVE_COMPLETED, progress: 100, fileIdCreated: data.fileIdCreated });
            }
            setTimeout(() => {
              updateFileInQueue(id, { status: UPLOAD_STATUS.COMPLETED });
            }, 1000);
          } else {
            if (activeUploadIndex >= files.length - 1 && !ensureFoldersCalled) {
              ensureFoldersCalled = true;
              ensureFoldersExist();
            }
            incrementUploadingFolder(id);
            uploadFileWithRetry();
          }
        };

        const onUploadFailed = async (delayMs, errorCode = null, shouldNotRetry = false) => {
          if (attempt >= MAX_FAILED_UPLOADS_RETRIES || shouldNotRetry || shouldNotRetryForError(errorCode)) {
            if (!foldersObject) {
              ensureFoldersExist();
              updateFileInQueue(id, { status: UPLOAD_STATUS.FADING_ACTIVE_FAILED, progress: 100 });
              setTimeout(() => {
                updateFileInQueue(id, { status: UPLOAD_STATUS.FAILED });
              }, 1000);
            } else {
              if (activeUploadIndex >= files.length - 1 && !ensureFoldersCalled) {
                ensureFoldersCalled = true;
                ensureFoldersExist();
              }
              incrementUploadingFolder(id);
              uploadFileWithRetry();
            }
            if (errorCode) {
              fileObj.errorCode = errorCode;
            }
            updateFailedItems({
              id,
              files: [fileObj],
              foldersObjectFromRetry: foldersObject,
              uniqueFolders: new Set(),
              folderidCurrent,
              overwrite,
              renameifexists,
            });
          } else {
            ++attempt;
            const waitTime = delayMs ? delayMs : fibonacci(attempt) * 1000;
            await delay(waitTime);
            uploadFileWithRetry(attempt, currentActiveUploadIndex);
          }
        };

        const onUploadAborted = () => {
          if (isAborted) {
            return;
          }
          isAborted = true;
          ensureFoldersExist();
        }

        if (folderid === false) {
          onUploadFailed(100);
          return;
        }

        const optsFileUpload = { auth: opts.auth, nopartial: 1, folderid, overwrite, renameifexists };

        if (isDlink) {
          const url = window.location.href;
          const urlObj = new URL(url);
          optsFileUpload.code = urlObj.searchParams.get('code');
        }

        //  else if (fileObj.size > FILE_SIZE_FOR_CHUNKS && !isDlink) {
        //   fileUploadService.uploadFileToServerChunks(fileObj, onProgress, onUploadComplete, onUploadFailed, optsFileUpload, signal, !foldersObject, onUploadAborted);
        // }
        if (encrypted) {
          fileUploadService.cryptoUpload(fileObj, onProgress, onUploadComplete, onUploadFailed, optsFileUpload, signal, !foldersObject, onUploadAborted);
        } else {
          fileUploadService.uploadFileToServer(fileObj, onProgress, onUploadComplete, onUploadFailed, optsFileUpload, signal, !foldersObject, onUploadAborted);
        }
      };

      // start uploading 2 files
      uploadFileWithRetry();
      uploadFileWithRetry();
    };

    const ensureFoldersExist = async () => {
      const uploadItem = items[activeItemUploadIndex];
      if (!uploadItem) {
        setIsUploading(false);
        return;
      }
      ++activeItemUploadIndex;
      let { uniqueFolders, id, files, folderidCurrent, overwrite, renameifexists, skip, encrypted, foldersObjectFromRetry } = uploadItem;
      const abortController = abortControllersMap ? abortControllersMap.get(id) : abortControllers.get(id);
      const { signal } = abortController;
      if (signal?.aborted) {
        ensureFoldersExist();
        return;
      };
      let foldersObject = foldersObjectFromRetry || undefined;
      let uploadedFoldersCount = 0;
      if (uniqueFolders.size !== 0 && !foldersObject) {
        // check if folder exists
        // if option is already selected, do not check
        if (skip) {
          ensureFoldersExist();
          return;
        } else if (!renameifexists && !overwrite) {
          const folderExists = fileExistsCache(folderidCurrent, [...uniqueFolders][0], true);
          if (folderExists) {
            handleExistingUpload(uploadItem);
            ensureFoldersExist();
            return;
          }
        }

        foldersObject = {};
        const foldersGroupedByDepth = [];

        uniqueFolders.forEach(folder => {
          const depth = (folder.match(/\//g) || []).length; // Count slashes to determine depth
          if (!foldersGroupedByDepth[depth]) {
            foldersGroupedByDepth[depth] = [];
          }
          foldersGroupedByDepth[depth].push(folder);
        });
        for (const depth in foldersGroupedByDepth) {
          const folderChunks = chunkArray(foldersGroupedByDepth[depth], MAX_CONCURRENT_FOLDER_UPLOADS);
          let folderName = '';
          for (const folderChunk of folderChunks) {
            let resUploadPromises = undefined, parentFolderPath = undefined;
            for (let attempt = 1; attempt <= MAX_FAILED_UPLOADS_RETRIES; attempt++) {
              if (signal?.aborted) {
                ensureFoldersExist();
                return;
              };
              try {
                const uploadPromises = folderChunk.map(folder => {
                  let folderPaths = folder.split("/");
                  folderName = depth === "0" && renameifexists ? uniqFilename(foldersGroupedByDepth[0][0], folderidCurrent, true) : folderPaths[folderPaths.length - 1];
                  parentFolderPath = getParentFolderPathFromArrayOfPaths(folderPaths);
                  return foldersObject[parentFolderPath] === false ?
                    Promise.reject(false) :
                    fileUploadService.getOrCreateFolder(folderName, foldersObject[parentFolderPath] || folderidCurrent, encrypted, setFailedFolderError);
                });
                resUploadPromises = await Promise.all(uploadPromises);
                break;
              } catch (error) {
                if (attempt >= MAX_FAILED_UPLOADS_RETRIES || error === false) {
                  if (error === false) {
                    const waitTime = 100;
                    await delay(waitTime);
                  }
                  updateFailedItems({ id, uniqueFolders: new Set(folderChunk), files: [], foldersObjectFromRetry: foldersObject, folderidCurrent, renameifexists, overwrite });
                  break;
                } else {
                  const waitTime = fibonacci(attempt + 1) * 1000;
                  await delay(waitTime);
                }
              }
            }
            folderChunk.forEach((folder, index) => {
              foldersObject[folder] = resUploadPromises ? resUploadPromises[index] : false;
            })
            uploadedFoldersCount += folderChunk.length;
            if (depth === "0") {
              updateFileInQueue(id, { progress: uploadedFoldersCount, folderidCreated: foldersObject[foldersGroupedByDepth[depth][0]] });
            } else {
              updateFileInQueue(id, { progress: uploadedFoldersCount });
            }
          }
        }
      }
      if (files.length) {
        uploadFiles(uploadItem, foldersObject, signal);
      } else {
        ensureFoldersExist();
        updateFileInQueue(id, { status: UPLOAD_STATUS.FADING_ACTIVE_COMPLETED });
        setTimeout(() => {
          updateFileInQueue(id, { status: UPLOAD_STATUS.COMPLETED });
        }, 1000);
      }
    }
    // start uploading item
    const shouldStartTwoItemUploads = items[activeItemUploadIndex]?.uniqueFolders.size === 0 && items[activeItemUploadIndex + 1]?.uniqueFolders.size === 0;
    ensureFoldersExist();
    // start uploading second item if items are files
    if (shouldStartTwoItemUploads) {
      ensureFoldersExist();
    }
  }

  const updateFileInQueue = (id, updates) => {
    setUploadQueue(currentQueue => {

      const index = currentQueue.findIndex(item => item.id === id);
      if (index !== -1) {
        const newQueue = [...currentQueue];
        newQueue[index] = { ...newQueue[index], ...updates };
        return newQueue;
      }
      return [...currentQueue];
    }
    );
  };

  // for folders
  const incrementUploadingFolder = (id, isFailed) => {
    let isLastItem = false;
    setUploadQueue(currentQueue => {
      const upload = currentQueue.find((item) => item.id === id)
      if (upload) {
        upload.progress = upload.progress + 1;
        if (upload.progress === upload.size) {
          isLastItem = true;
          setFinishedFolderItemToUpdate(id);
        }
      }
      return [...currentQueue];
    });

  }


  const updateFailedItems = (item) => {
    const { id, files, uniqueFolders } = item;
    setFailedItems(currentItems => {
      const index = currentItems.findIndex(item => item.id === id);
      if (index === -1) {
        currentItems.push(item);
      } else {
        currentItems[index] =
        {
          ...currentItems[index],
          files: [...currentItems[index].files, ...files],
          uniqueFolders: new Set([...currentItems[index].uniqueFolders, ...uniqueFolders])
        };
      }
      return [...currentItems];
    }
    );
  };

  const abortUploadAction = (id) => {
    const abortController = abortControllers.get(id);
    setUploadQueue((prev) => prev.filter((item) => item.id !== id));
    setItemsForUpload((prev) => prev.filter((item) => item.id !== id));
    setAbortControllers((prev) => {
      const newControllers = new Map(prev);
      newControllers.delete(id);
      return newControllers;
    });
    if (abortController) {
      abortController.abort();
    }
  };

  const handleExistingUpload = (uploadItem) => {
    const { id, optionsModalId } = uploadItem;

    setUploadQueue((prev) => {
      const filteredQueue = prev.filter((item) => {
        if (item.id === id) {
          setUploadQueueExisting((prevExisting) => [...prevExisting, ...[item]]);
          return false;
        }
        return true;
      });
      return filteredQueue;
    });

    setItemsForUploadExisting((prevExisting) => [...prevExisting, ...[uploadItem]]);
  };

  const handleRetryAction = async (id) => {
    const currentItemInQueue = uploadQueue.find((item) => item.id === id);
    let failedItem;
    updateFileInQueue(id, { status: UPLOAD_STATUS.FADING_FAILED_RETRY });
    setTimeout(() => {
      setFailedItems((prev) => {
        failedItem = failedItems.find((item) => item.id === id);
        let progress = currentItemInQueue.isFile ? 0 : currentItemInQueue.size - (failedItem.uniqueFolders.size + failedItem.files.length);
        const filteredFailedItems = failedItems.filter((item) => item.id !== id);
        updateFileInQueue(id, { status: UPLOAD_STATUS.PENDING, progress });
        return [...filteredFailedItems];
      })
      if (!isUploading) {
        setIsUploading(true);
        processItemsUpload([failedItem]);
      } else {
        setItemsForUpload((prev) => [...prev, failedItem]);
      }
    }, 1000)
  }

  const cancelUpload = () => {
    const pendingUploads = uploadQueue.filter((item) => item.status === UPLOAD_STATUS.PENDING);
    const pendingIds = [];
    pendingUploads.forEach((item) => {
      pendingIds.push(item.id);
    })

    setUploadQueue((prev) => prev.filter((item) => !pendingIds.includes(item.id)));
    setItemsForUpload((prev) => prev.filter((item) => !pendingIds.includes(item.id)));
    setAbortControllers((prev) => {
      const newControllers = new Map(prev);
      pendingIds.reverse().forEach((id) => {
        const abortController = prev.get(id);
        abortController.abort();
        newControllers.delete(id);
      })
      return newControllers;
    });
  };

  const closeUpload = () => {
    const pendingUploads = uploadQueue.filter((item) => item.status === UPLOAD_STATUS.PENDING);
    const pendingIds = [];
    pendingUploads.forEach((item) => {
      pendingIds.push(item.id);
    })

    setUploadQueue([]);
    setItemsForUpload([]);
    setAbortControllers((prev) => {
      pendingIds.reverse().forEach((id) => {
        const abortController = prev.get(id);
        abortController.abort();
      })
      return new Map();
    });
  };


  // upload starts here
  // Process and start uploading files with concurrency control
  const initializeUploads = async (data, remoteUrl) => {
    let folderidCurrent = $.bbq.getState('folder') || currentFolder || 0;
    if (!data && !remoteUrl) {
      return
    } else if (remoteUrl) {
      updateUploadQueueItemsFromRemoteUrl(remoteUrl, folderidCurrent);
      return;
    }

    const { items, isDrop, isRemoteUpload, pasteItemUrl } = data;

    if (pasteItemUrl) {
      // paste has only 1 item, if default item generate name
      setIsUploadPasteModalOpen({ items, pasteItemUrl });
      return;
    }

    if (isRemoteUpload) {
      setIsUploadRemoteModalOpen(true);
      return;
    }

    const encrypted = opts.encrypted;

    if (encrypted && !folderidCurrent) {
      folderidCurrent = (await fileUploadService.findCryptoFolder()).folderid;
    }

    // handle drag/drop and button cases
    if (isDrop) {
      updateUploadQueueItemsFromDrop(items, folderidCurrent, encrypted);
    } else {
      if (!items?.length) return;
      updateUploadQueueItemsFromButton(items, folderidCurrent, encrypted);
    }
  };

  const getFilesDataTransferItems = (dataTransferItem) => {
    let files = [], folders = new Set();
    const traverseFileTreePromise = (item) => {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            Object.defineProperty(file, 'webkitRelativePath', {
              value: item.fullPath.substring(1),
              writable: false
            });
            files.push(file);
            resolve(file);
          }, _ => resolve());
        } else if (item.isDirectory) {
          folders.add(item.fullPath.substring(1));
          let dirReader = item.createReader();
          readAllEntries(dirReader).then(entries => {
            let entriesPromises = entries.map(entry => traverseFileTreePromise(entry));
            resolve(Promise.all(entriesPromises))
          })
        }
      });
    }

    const readAllEntries = (dirReader, allEntries = []) => {
      return new Promise((resolve, reject) => {
        dirReader.readEntries(entries => {
          if (entries.length === 0) {
            resolve(allEntries); // No more entries, resolve the promise with all collected entries
          } else {
            allEntries = [...allEntries, ...entries]; // Add the new entries to the array
            readAllEntries(dirReader, allEntries).then(resolve).catch(reject); // Recursively read more entries
          }
        });
      });
    }

    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      entriesPromises.push(
        traverseFileTreePromise(dataTransferItem.webkitGetAsEntry())
      );
      Promise.all(entriesPromises).then(() => {
        resolve([files, folders]);
      });
    });
  }

  const updateUploadQueueItemsFromDrop = async (dataTransfer, folderidCurrent, encrypted) => {
    // extract dataTransfer
    const { files, items } = dataTransfer, formattedFiles = [];
    const abortControllersMap = new Map();
    const optionsModalId = uuidv4();

    // handle initial drop state
    for (let i = 0; i < files.length; ++i) {
      const id = uuidv4();
      const abortController = new AbortController();
      const isFile = items[i].webkitGetAsEntry().isFile;
      formattedFiles.push({
        name: files[i].name,
        status: UPLOAD_STATUS.PENDING,
        progress: 0,
        isFile,
        size: isFile ? files[i].size : undefined,
        id,
        folderidCurrent,
        optionsModalId,
        encrypted,
      });
      abortControllersMap.set(id, abortController);
    }
    setAbortControllers(prev => new Map([...prev, ...abortControllersMap]));

    setUploadQueue((prev) => [...formattedFiles, ...prev]);

    // size for folders
    const formattedFilesItemsLength = Array(formattedFiles.length);
    const formattedItemsForUpload = [];

    // extract files and folders from drop
    await Promise.all(formattedFiles.map(async (file, index) => {
      const [actualFiles, folders] = await getFilesDataTransferItems(items[index]);
      let totalFilesSize = 0;
      if (!file.isFile) {
        actualFiles.forEach((file) => {
          totalFilesSize += file.size;
        })
        totalFilesSize += folders.size * 1024;
      }
      formattedFilesItemsLength[index] = { id: file.id, size: folders.size + actualFiles.length, totalFilesSize };
      formattedItemsForUpload.push({
        id: file.id, files: file.isFile ? [files[index]] : actualFiles, uniqueFolders: folders, folderidCurrent, optionsModalId, renameifexists: 0, overwrite: 0, skip: 0, encrypted
      });
    }));

    // set folders length
    setUploadQueue(prev => {
      formattedFilesItemsLength.forEach((itemLength) => {
        prev.forEach((prevItem, index) => {
          if (!prevItem.isFile && prevItem.id === itemLength.id) {
            prev[index].size = itemLength.size;
            prev[index].totalFilesSize = itemLength.totalFilesSize;
          }
        })
      });
      return [...prev];
    });

    // handle upload
    if (!isUploading) {
      setIsUploading(true);
      processItemsUpload(formattedItemsForUpload, abortControllersMap);
    } else {
      setItemsForUpload((prev) => [...prev, ...formattedItemsForUpload]);
    }
  }

  const updateUploadQueueItemsFromButton = async (items, folderidCurrent, encrypted) => {
    const isFile = items[0].webkitRelativePath === "";
    const uniqueFolders = isFile ? new Set() : getUniqueFolders(items);
    const optionsModalId = uuidv4();
    // for instant upload
    const formattedFiles = [];
    const formattedItemsForUpload = [];
    const abortControllersMap = new Map();

    let abortController;
    if (isFile) {
      // handle multiple files
      Array.from(items).forEach((item, index) => {
        abortController = new AbortController();
        const id = uuidv4();
        formattedFiles.push({
          name: item.name,
          status: UPLOAD_STATUS.PENDING,
          progress: 0,
          isFile: true,
          size: item.size,
          id,
          optionsModalId,
          folderidCurrent,
          encrypted,
        });
        formattedItemsForUpload.push({ files: [item], uniqueFolders, id, folderidCurrent, optionsModalId, renameifexists: 0, overwrite: 0, encrypted });
        abortControllersMap.set(id, abortController);
      })
    } else {
      // handle single folder - multiple not supported via button, only drop
      const id = uuidv4();
      abortController = new AbortController();
      const folderName = items[0].webkitRelativePath.split('/')[0];
      let totalFilesSize = 0;
      Array.from(items).forEach((item) => {
        totalFilesSize += item.size;
      })
      totalFilesSize += uniqueFolders.size * 1024
      formattedFiles.push({
        name: folderName,
        status: UPLOAD_STATUS.PENDING,
        progress: 0,
        isFile: false,
        size: uniqueFolders.size + items.length,
        id,
        optionsModalId,
        folderidCurrent,
        encrypted,
        totalFilesSize,
      });
      formattedItemsForUpload.push({ files: items, id, uniqueFolders, folderidCurrent, optionsModalId, renameifexists: 0, overwrite: 0, encrypted });
      abortControllersMap.set(id, abortController);
    }

    setUploadQueue((prev) => [...formattedFiles, ...prev]);
    setAbortControllers(prev => new Map([...prev, ...abortControllersMap]));

    // handle upload
    if (!isUploading) {
      setIsUploading(true);
      processItemsUpload(formattedItemsForUpload, abortControllersMap);
    } else {
      setItemsForUpload((prev) => [...prev, ...formattedItemsForUpload]);
    }
  }

  const updateUploadQueueItemsFromRemoteUrl = (remoteUrl, folderidCurrent) => {
    if (remoteUrl.length < 5)
      return;
    let m = false, formattedFiles = [];
    const formattedItemsForUpload = [];
    const abortControllersMap = new Map();
    const optionsModalId = uuidv4();

    if (m = remoteUrl.match(/"name: ([^"]+)+"/)) {
      remoteUrl = remoteUrl.replace(m[0], '');
    }

    const abortController = new AbortController();
    const id = uuidv4();
    formattedFiles.push({
      name: '',
      status: UPLOAD_STATUS.PENDING,
      progress: 0,
      isFile: true,
      size: undefined,
      id,
      optionsModalId,
      folderidCurrent,
      url: remoteUrl,
    });

    formattedItemsForUpload.push({ files: [{ url: remoteUrl }], uniqueFolders: new Set(), id, folderidCurrent, optionsModalId, renameifexists: 0, overwrite: 0 });
    abortControllersMap.set(id, abortController);

    setUploadQueue((prev) => [...formattedFiles, ...prev]);
    setAbortControllers(prev => new Map([...prev, ...abortControllersMap]));
    console.log({ formattedFiles })
    // handle upload
    if (!isUploading) {
      setIsUploading(true);
      processItemsUpload(formattedItemsForUpload, abortControllersMap);
    } else {
      setItemsForUpload((prev) => [...prev, ...formattedItemsForUpload]);
    }
  }

  const donePercentage = useMemo(() => {
    let doneItems = 0, failedItems = 0, pendingItems = 0;
    uploadQueue.forEach((item) => {
      if (item.status === UPLOAD_STATUS.COMPLETED ||
        item.status === UPLOAD_STATUS.FAILED ||
        item.status === UPLOAD_STATUS.FADING_ACTIVE_COMPLETED ||
        item.status === UPLOAD_STATUS.FADING_ACTIVE_FAILED) {
        ++doneItems;
        if (item.status === UPLOAD_STATUS.FAILED ||
          item.status === UPLOAD_STATUS.FADING_ACTIVE_FAILED) {
          ++failedItems;
        }
      } else if (item.status === UPLOAD_STATUS.PENDING) {
        ++pendingItems;
      }
    })
    const percentage = Math.round((doneItems / uploadQueue.length) * 100);
    const isUploading = percentage !== 100 && uploadQueue.length > 0;
    return { isUploading, failedItems, pendingItems };
  }, [uploadQueue])

  const handleUploadOptionsModalClose = (id) => {
    setUploadQueueExisting(prev => prev.slice(1));
    setItemsForUploadExisting(prev => prev.slice(1));
    setAbortControllers(prev => {
      const newMap = new Map(prev);
      newMap.delete(id);
      return newMap;
    });
  }

  const handleUploadOptionsModalYesClick = (selectedValueExistingItems, shouldApplyForAll, id, optionsModalId) => {
    const overwrite = selectedValueExistingItems === UPLOAD_OPTIONS.REPLACE;
    const renameifexists = selectedValueExistingItems === UPLOAD_OPTIONS.DUPLICATE;
    const skip = selectedValueExistingItems === UPLOAD_OPTIONS.SKIP;
    if (!shouldApplyForAll) {
      setUploadQueueExisting(prev => {
        let uniqName;
        let item = prev.find((prevItem) => prevItem.id === id);
        const isFile = item.isFile;
        if (renameifexists) {
          uniqName = uniqFilename(item.name, item.folderidCurrent, !isFile);
          item.name = uniqName;
        } else if (skip) {
          item = undefined;
          setAbortControllers(prev => {
            const newMap = new Map(prev);
            newMap.delete(id);
            return newMap;
          });
        }
        if (item) {
          setUploadQueue((prevUploadQueue) => [...[item], ...prevUploadQueue]);
        }
        setItemsForUploadExisting(prevItemsForUploadExisting => {
          let itemForUpload = prevItemsForUploadExisting.find((prevItem) => prevItem.id === id);
          if (overwrite) {
            itemForUpload.overwrite = 1;
          } else if (renameifexists) {
            itemForUpload.renameifexists = 1;
          } else if (skip) {
            itemForUpload = undefined;
          }
          if (itemForUpload) {
            if (!isUploading) {
              setIsUploading(true);
              processItemsUpload([itemForUpload]);
            } else {
              setItemsForUpload((prev) => [...prev, ...[itemForUpload]]);
            }
          }
          return prevItemsForUploadExisting.filter((prevItem) => prevItem.id !== id);
        });
        return prev.filter((prevItem) => prevItem.id !== id);
      });
    } else {
      setSelectedOptionMap((prev) => {
        const newMap = new Map(prev);
        newMap.set(optionsModalId, selectedValueExistingItems);
        return newMap;
      });
      setUploadQueueExisting((prev) => {
        prev.forEach((prevItem, index) => {
          if (renameifexists) {
            prev[index].renameifexists = 1;
            const isFile = prev[index].isFile;
            const uniqName = uniqFilename(prev[index].name, prev[index].folderidCurrent, !isFile);
            prev[index].name = uniqName;
          } else if (skip) {
            prev.splice(index, 1);
          } else if (overwrite) {
            prev[index].overwrite = 1;
          }
        })
        if (skip) {
          setAbortControllers(prevControllers => {
            const newMap = new Map(prevControllers);
            prev.forEach((item) => {
              newMap.delete(item.id);
            })
            return newMap;
          });
        }
        setUploadQueue((prevUploadQueue) => [...prev, ...prevUploadQueue]);

        setItemsForUploadExisting((prevItemsForUploadExisting) => {
          prevItemsForUploadExisting.forEach((prevItem, index) => {
            if (overwrite) {
              prevItemsForUploadExisting[index].overwrite = 1;
            } else if (renameifexists) {
              prevItemsForUploadExisting[index].renameifexists = 1;
            } else if (skip) {
              prevItemsForUploadExisting.splice(index, 1);
            }
          });
          if (!isUploading) {
            setIsUploading(true);
            processItemsUpload(prevItemsForUploadExisting);
          } else {
            setItemsForUpload((prevItemsForUpload) => [...prevItemsForUpload, ...prevItemsForUploadExisting]);
          }
          return [];
        });
        return [];
      });
    }
  }

  const handleClear = useCallback(() => {
    setUploadQueue((prev) => activeTab === UPLOAD_TAB.COMPLETED ? prev.filter((item) => item.status !== UPLOAD_STATUS.COMPLETED) : []);
    setFailedItems([]);
  }, [activeTab])

  const handleRetryAll = () => {
    setUploadQueue((prev) => prev.map((item) => {
      if (item.status === UPLOAD_STATUS.FAILED) {
        item.status = UPLOAD_STATUS.FADING_FAILED_RETRY
      }
      return item;
    }));
    setTimeout(() => {
      const failedItemsForUpload = [];
      setFailedItems((prevFaileditems) => {
        setUploadQueue((prev) => {
          prev = prev.map((item) => {
            if (item.status === UPLOAD_STATUS.FADING_FAILED_RETRY) {
              const failedItem = failedItems.find((failedItemCurrent) => failedItemCurrent.id === item.id);
              failedItemsForUpload.push(failedItem);
              let progress = item.isFile ? 0 : item.size - (failedItem.uniqueFolders.size + failedItem.files.length);
              item.status = UPLOAD_STATUS.PENDING
              item.progress = progress
            }
            return item;
          })
          return [...prev]
        })
        return [];
      });

      if (!isUploading) {
        setIsUploading(true);
        processItemsUpload([...failedItemsForUpload]);
      } else {
        setItemsForUpload((prev) => [...prev, ...failedItemsForUpload]);
      }
    }, 1000)
  }

  // Option is already selected
  useEffect(() => {
    const option = selectedOptionMap.get(uploadQueueExisting[0]?.optionsModalId);
    if (option) {
      handleUploadOptionsModalYesClick(option, false, uploadQueueExisting[0].id, uploadQueueExisting[0].optionsModalId);
    }
  }, [uploadQueueExisting]);

  useEffect(() => {
    const { filteredItems, tabItemsLength } = filterFilesByTab(uploadQueue, activeTab);
    setFilteredUploadQueue([...filteredItems]);
    setTabItemsLength(tabItemsLength);
  }, [activeTab, uploadQueue]);

  // handle beforeunload if upload is running
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUploading) {
        const message = 'An upload is in progress. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUploading]);

  useEffect(() => {
    if (finishedFolderItemToUpdate) {
      setFinishedFolderItemToUpdate(undefined);
      let failedItem = undefined;
      setUploadQueue(currentQueue => {
        const upload = currentQueue.find((item) => item.id === finishedFolderItemToUpdate)
        if (upload) {
          failedItem = failedItems.find((item) => item.id === finishedFolderItemToUpdate);
          upload.status = failedItem ? UPLOAD_STATUS.FADING_ACTIVE_FAILED : UPLOAD_STATUS.FADING_ACTIVE_COMPLETED;
        }
        return [...currentQueue];
      });

      setTimeout(() => {
        setUploadQueue(currentQueue => {
          const upload = currentQueue.find((item) => item.id === finishedFolderItemToUpdate)
          if (upload) {
            upload.status = failedItem ? UPLOAD_STATUS.FAILED : UPLOAD_STATUS.COMPLETED;
          }
          return [...currentQueue];
        });
      }, 1000)
    }

  }, [finishedFolderItemToUpdate])

  const handleUploadRemoteModalClose = () => setIsUploadRemoteModalOpen(false);

  const handleUploadRemoteModalYesClick = (remoteUrl) => {
    handleUploadRemoteModalClose();
    initializeUploads(undefined, remoteUrl);
  }

  const handleUploadPasteModalClose = () => setIsUploadPasteModalOpen(false);

  const handleUploadPasteModalYesClick = (items) => {
    handleUploadPasteModalClose();
    initializeUploads({ items, isDrop: false });
  }

  return {
    initializeUploads,
    uploadQueue,
    handleRetryAction,
    donePercentage,
    cancelUpload,
    closeUpload,
    failedItems,
    abortUploadAction,
    uploadQueueExisting,
    handleUploadOptionsModalClose,
    handleUploadOptionsModalYesClick,
    activeTab,
    setActiveTab,
    failedFolderError,
    setFailedFolderError,
    filteredUploadQueue,
    handleClear,
    handleRetryAll,
    tabItemsLength,

    isUploadRemoteModalOpen,
    handleUploadRemoteModalClose,
    handleUploadRemoteModalYesClick,

    isUploadPasteModalOpen,
    handleUploadPasteModalClose,
    handleUploadPasteModalYesClick,
  };
};

export default useFileUpload;