//@flow

import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { ArrowIcon } from "../../web-shared/components/ButtonDefault";

type PromoStickyBarV1Props = {
  bgcolor: string,
  title: string,
  titlecolor: string,
  btnlink: string,
  btncolor: string,
  btntext: string,
  leftimg: string,
  leftimg2x: string,
  rightimg: string,
  rightimg2x: string,
  imgSize: string,
  label: string,
  channelid: string,
  discountText?: string,
  discountColor?: string,
  onActionBtnClick: () => void,
  onMountStickyBar: () => void,
}

const PromoStickyBarV1 = ({
  bgcolor,
  title,
  titlecolor,
  btnlink,
  btncolor,
  btntext,
  leftimg,
  leftimg2x,
  rightimg,
  rightimg2x,
  imgSize,
  label,
  channelid,
  discountText,
  discountColor,
  onActionBtnClick,
  onMountStickyBar,
} : PromoStickyBarV1Props) => {

  const isRetina = window.devicePixelRatio > 1;
  const imgWidth = imgSize.split("x")[0];
  const imgHeight = imgSize.split("x")[1];

  useEffect(() => {
    onMountStickyBar();
  }, []);

  return (
    <Wrapper bgcolor={bgcolor}>
      <a href={btnlink} onClick={onActionBtnClick}>
        <LeftImage src={isRetina ? leftimg2x : leftimg } width={imgWidth} height={imgHeight}/>
        <Text color={titlecolor}>
          {discountText ? <Discount color={discountColor} >{discountText}</Discount> : null}
          <span>{title}</span>
          <Link color={btncolor}>
            <span>{btntext}</span>
            <ArrowIcon rotation="right" bgcolor={btncolor} arrowcolor={bgcolor}><i/></ArrowIcon>
          </Link>
        </Text>
        <RightImage src={isRetina ? rightimg2x : rightimg} width={imgWidth} height={imgHeight}/>
      </a>
    </Wrapper>
  )
}

export default PromoStickyBarV1;

const Wrapper = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${props => props.bgcolor ? "#" + props.bgcolor : "#000000"};
    a {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
`;

const PromoImage = styled.img``;

const LeftImage = styled(PromoImage)``;
const RightImage = styled(PromoImage)``;

const Text = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: -0.45px;
  text-align: center;
  color: ${props => props.color ? "#" + props.color : "#ffffff"};
`;

const Link = styled.span`
  color: ${props => props.color ? "#" + props.color : "#ffffff"};
  margin-left: 10px;
`;

const Discount = styled.span`
  color: ${props => props.color ? "#" + props.color : "#ffffff"};
  margin-right: 10px;
`;