import React from "react";
import {VolumeInput, VolumeInputWrapper} from "../../../userinterface/styles";
import usePlayerContext from "../../../hooks/usePlayerContext";
import ActionButton from "../../../userinterface/ActionButton";
import {createPortal} from "react-dom";
import OverlayPanelContainer from "./OverlayPanelContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVolumeSlash} from '@fortawesome/pro-solid-svg-icons/faVolumeSlash'
import {getPlayerState} from "../../../helpers";

const VolumeOverlay = () => {
    const {
        volume,
        onVolumeChange,
        onVolumeChangeEnd,
        onMuteUnmute,
        additionalClass,
        playerVariant
    } = usePlayerContext();

    const handleOnWheel = (e) => {
        e.stopPropagation();
        if (e.deltaY < 0) {
            onVolumeChange(Math.min(volume + 0.02, 1));
        } else if (e.deltaY > 0) {
            onVolumeChange(Math.max(volume - 0.02, 0));
        }
    };

    const container = document.getElementById('volumeContainer');

    if (!container) {
        return null;
    }

    return (
        <>
            {createPortal(
                <OverlayPanelContainer
                    $width={65}
                    onWheel={handleOnWheel}
                    className={additionalClass + ' volume'}
                >
                    <VolumeInputWrapper>
                        <VolumeInput
                            aria-label="volume"
                            name="volume"
                            type="range"
                            min={0}
                            step={0.01}
                            max={1}
                            value={volume}
                            style={{
                                "--progress-volume": volume,
                            }}
                            onChange={(e) => {
                                onVolumeChange(e.currentTarget.valueAsNumber);
                            }}
                            onMouseUp={(e) => {
                                onVolumeChangeEnd(e.currentTarget.valueAsNumber);

                                if (typeof gtag === "function") {
                                    gtag("event", "audio_player_click", {
                                        action: "volume",
                                        state: getPlayerState(playerVariant)
                                    });
                                }
                            }}
                        />
                    </VolumeInputWrapper>
                    <ActionButton
                        onClick={(e) => {
                            onMuteUnmute(e);

                            if (typeof gtag === "function") {
                                gtag("event", "audio_player_click", {
                                    action: volume > 0 ? "mute" : "unmute",
                                    state: getPlayerState(playerVariant)
                                });
                            }
                        }}
                        className={volume === 0 ? 'svg-color-prime' : 'svg-color-gray'}
                        label={<FontAwesomeIcon style={{fontSize: 16}} icon={faVolumeSlash} />}
                    />
                </OverlayPanelContainer>,
                container
            )}
        </>
    );
}

export default VolumeOverlay;