import {useState, useEffect} from 'react';
import useWindowDimensions from "../../../lib/hooks/useWindowDimensions";
import {getAdditionalClass, getPlayerState, mediaSessionActions, turnOffOverflow, turnOnOverflow} from "../helpers";
import {GENERAL_TRANSITION_DELAY, MINIMIZED_HEIGHT} from "../constants";

type Props = {
    isPublic: boolean,
    isMobile: boolean,
    queue: Object<any>,
    audioRef: Object<any>
};

const usePlayerBoxControl = ({
    isPublic = false,
    isMobile = false,
    restoreState = false,
    queue,
    audioRef,
    action,
    random
}: Props) => {
    const { height } = useWindowDimensions();

    const [playerVariant, setPlayerViriant] = useState({
        showPlayer: false,
        isFullPage: isPublic && !isMobile && !restoreState,
        isMiniPlayer: true,
        isMinimize: restoreState
    });
    const [isWhiteBackground, setIsWhiteBackground] = useState(false);
    const [showOpenInAppButton, setShowOpenInAppButton] = useState(true);

    useEffect(() => {
        HFN.uploadControl.minimize();
        HFN.bottomFixedControls.calculatePageBottom.add(MINIMIZED_HEIGHT);

        if (typeof gtag === "function") {
            gtag("event", "audio_player_view", {
                action: "open",
                state: restoreState ? "minimized" : (isPublic ? "fullpage" : "expanded")
            });
        }
    }, []);

    useEffect(() => {
        if (action) {
            if (action === 'pause') {
                audioRef.current?.pause();
            }
            if (action === 'minimize') {
                onPlayerMinimize()
            }
        }
    }, [action, random]);

    useEffect(() => {
        if (!isMobile) {
            return;
        }

        if (height < 480) {
            onShowPlayer();
        }
    }, [isMobile, height]);

    useEffect(() => {
        if (!queue.length) {
            return;
        }

        const hiddenUnderElement = document.getElementsByClassName('hidden-under')[0];

        if (!playerVariant.showPlayer) {
            HFN.bottomFixedControls.calculatePageBottom.remove(MINIMIZED_HEIGHT);
            setTimeout(() => {
                if (hiddenUnderElement) {
                    hiddenUnderElement.removeAttribute('style');
                }
                HFN.audioPlayer.destroy();
            }, GENERAL_TRANSITION_DELAY + 50);
            audioRef.current?.pause();
        } else {
            if (isMobile && height < 480) {
                onPlayerMinimize(null, true);
            }
            if (hiddenUnderElement) {
                hiddenUnderElement.style.display = 'none';
            }
        }

    }, [playerVariant.showPlayer]);

    useEffect(() => {
        if (!queue.length) {
            setPlayerViriant({...playerVariant, showPlayer: false});
        } else {
            setPlayerViriant({...playerVariant, showPlayer: true});
        }
    }, [queue]);

    const onPlayerClose = (e) => {
        if (e) {
            e.stopPropagation()
        }

        if (typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "close",
                state: getPlayerState(playerVariant)
            });
        }

        mediaSessionActions.clear();
        localStorage.removeItem('playerformail');
        localStorage.removeItem('playerqueue');
        localStorage.removeItem('playercurrent');
        localStorage.removeItem('playerplist');
        localStorage.removeItem('playerpcode');

        setPlayerViriant({...playerVariant, showPlayer: false});
    }

    const onPlayerMinimize = (e, auto = false) => {
        if (e) {
            e.stopPropagation()
        }

        if (!auto && typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "minimize",
                state: getPlayerState(playerVariant)
            });
        }

        setPlayerViriant({
            ...playerVariant,
            isMinimize: true,
            isMiniPlayer: true,
        });
    }

    const onPlayerMaximize = (e) => {
        if (e) {
            e.stopPropagation()
        }
        if (isMobile && height < 480) {
            onPlayerMinimize(null, true);
            return;
        }

        if (typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "expand",
                state: getPlayerState(playerVariant)
            });
        }

        HFN.uploadControl.minimize();
        setPlayerViriant({
            ...playerVariant,
            isMinimize: false,
            isMiniPlayer: true,
        });
    }

    const onShowMiniPlayer = (e) => {
        if (e) {
            e.stopPropagation()
        }
        if (isMobile && height < 480) {
            onPlayerMinimize(null, true);
            return;
        }

        if (typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "expand",
                state: getPlayerState(playerVariant)
            });
        }

        setPlayerViriant({
            ...playerVariant,
            isFullPage: false,
            isMinimize: false,
            isMiniPlayer: true,
        });
    }

    const onShowFullPagePlayer = (e) => {
        if (e) {
            e.stopPropagation()
        }

        if (typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "fullpage",
                state: getPlayerState(playerVariant)
            });
        }
        setPlayerViriant({...playerVariant, isFullPage: true});
    }

    const onShowPlayer = (e) => {
        if (e) {
            e.stopPropagation()
        }
        if (isMobile && height < 480) {
            onPlayerMinimize(null, true);
            return;
        }

        if (typeof gtag === "function") {
            gtag("event", "audio_player_click", {
                action: "queue",
                state: getPlayerState(playerVariant)
            });
        }

        setPlayerViriant({...playerVariant, isMinimize: false, isMiniPlayer: false});
    }

    const additionalClass = getAdditionalClass(
        playerVariant,
        isMobile,
        isPublic,
        queue.length === 1
    );

    return {
        additionalClass,
        playerVariant,
        isWhiteBackground,
        showOpenInAppButton,
        setShowOpenInAppButton,
        setIsWhiteBackground,
        onPlayerClose,
        onPlayerMinimize,
        onPlayerMaximize,
        onShowMiniPlayer,
        onShowFullPagePlayer,
        onShowPlayer,
    }
}

export default usePlayerBoxControl;